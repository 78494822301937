// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { Transactions } from '@app/dto/Transactions';
import SessionStorage from '@app/helpers/SessionStorage';
import { DashboardService } from '@app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CurrencySettings } from '../../../../../../froexishared/src/dto/CurrencySettingsDto';

@AutoUnsubscribe()
@Component({
  selector: 'tranzactions-tile',
  templateUrl: './tranzactions-tile.component.html',
  styleUrls: ['./tranzactions-tile.component.scss']
})
export class TranzactionsTileComponent implements OnInit, OnDestroy {
  hasExiLicence = SessionStorage.HasExiLicence;
  hasExoLicence = SessionStorage.HasExoLicence;
  transactions: Transactions;
  getDashboardData$: Subscription;

  todayVisible: boolean=true;
  past7DayVisible: boolean;
  past30DayVisible: boolean;
  loaded: boolean = false;
  currencySettings: CurrencySettings = new CurrencySettings()
  intervalSelector = new FormControl('to')
  constructor(private dashboardSevice: DashboardService) { }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.currencySettings.CurrencyCode="HU"
    this.getDashboardData$ = this.dashboardSevice.GetDashboardData().subscribe({
      next: (result) => {
        this.transactions = result as Transactions
        this.loaded = true;
      },
      error: (error) => {  

      }
    })
  }
  ngOnDestroy(): void {
    this.getDashboardData$?.unsubscribe();

  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  changeInterval(event) {
    switch (event.value) {
      case "to":
        this.todayVisible = true;
        this.past7DayVisible = false;
        this.past30DayVisible = false;
        break;
      case "l7":
        this.todayVisible = false;
        this.past7DayVisible = true;
        this.past30DayVisible = false;
        break;
      case "l30":
        this.todayVisible = false;
        this.past7DayVisible = false;
        this.past30DayVisible = true;
        break;
    }
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  //#endregion =================================================================================================================





}
