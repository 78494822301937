import { Component, OnInit } from '@angular/core';
import SessionStorage from '@app/helpers/SessionStorage';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: ' sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  hasExiLicence : boolean;
  hasExoLicence : boolean;

  constructor(private authService : AuthService) {

  
  }

  ngOnInit() {
  
    //his.authService.loginComplete.subscribe( result=> {
      // this.hasExiLicence = SessionStorage.HasExiLicence
      // this.hasExoLicence =  SessionStorage.HasExoLicence
      // })
  }


  licenceFunction(menuPoint : string) : boolean {
    this.hasExiLicence = SessionStorage.HasExiLicence
    this.hasExoLicence =  SessionStorage.HasExoLicence
      switch(menuPoint) {
        case "checkindatasettings":
            if( !this.hasExiLicence && this.hasExoLicence)  {
              return true 
            } else {
              return false
            }
        case "paymentsettings":
          if(this.hasExiLicence && !this.hasExoLicence) {
            return true
          } else {
            return false;
          }
        case "services":
          if(this.hasExiLicence && !this.hasExoLicence) {
            return true
          } else {
            return false;
          }
      }
    return true;
  } 

}


/*
Csak EXI esetén:
Fizetési mód és szolgáltatások inaktív

Csak EXO esetén
Checkin adatok inaktív

*/