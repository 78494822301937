// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription , combineLatest, Subject, takeUntil, firstValueFrom} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { QuillEditorComponent } from 'ngx-quill';

// Hostware
import { LanguageService } from '../../../../../froexishared/src/services/language.service'
import { InvitationService } from '../../../../../froexishared/src/services/invitation.service';
import { Language } from '../../../../../froexishared/src/dto/Language';
import { InvitationDto } from '../../../../../froexishared/src/dto/InvitationDto';
import SessionStorage from '@app/helpers/SessionStorage';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import  AvailableLanguages  from '../../../../../froexishared/src/AvailableLanguages'
import { CustomizeEmailService } from '@app/services/customize-email.service';
import { TopbarService } from '@app/services/topbar.service';
import { BrandingService } from '../../../../../froexishared/src/services/branding.service';
import { BrandingDto } from '../../../../../froexishared/src/dto/BrandingDto';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import * as Quill from "quill"
import { CanComponentDeactivate } from '@app/interfaces/CanComponentDeactivate';
@AutoUnsubscribe()
@Component({
  selector: 'app-customize-email',
  templateUrl: './customize-email.component.html',
  styleUrls: ['./customize-email.component.scss']
})

export class CustomizeEmailComponent implements OnInit, OnDestroy {

  @ViewChild('editor') editor: QuillEditorComponent

  loadEmailData$: Subscription;
 
  saveEmailData$ : Subscription;

  loadLanguages$ :  Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  languages: Language[] = [];

  avaialableLanguages : string[]=[];
  invitations: InvitationDto[] = [];
  editedItem: InvitationDto;
  branding: BrandingDto;
  isDirty: boolean;
  editorForm: FormGroup;
  isNew: boolean;
  currIndex: number;
  loaded : boolean = false;
  constructor(
    private languageService: LanguageService,
    private invitationService: InvitationService,
    private hwAlertService: HwAlertService,
    private confirmService : HwConfirmationDialogService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private customizeEmailService: CustomizeEmailService,
    private topbarService : TopbarService,
    private brandingService: BrandingService
   
  ) {
    this.editorForm = fb.group({
      Language: new FormControl(''),
      Body: new FormControl('')
    })

    
  
    var DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);
    var AlignClass = Quill.import('attributors/class/align');
    Quill.register(AlignClass, true);
    var BackgroundClass = Quill.import('attributors/class/background');
    Quill.register(BackgroundClass, true);
    var ColorClass = Quill.import('attributors/class/color');
    Quill.register(ColorClass, true);
    var DirectionClass = Quill.import('attributors/class/direction');
    Quill.register(DirectionClass, true);
    var FontClass = Quill.import('attributors/class/font');
    Quill.register(FontClass, true);
    var SizeClass = Quill.import('attributors/class/size');
    Quill.register(SizeClass, true);
    var AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    var BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);
    var ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);
    var DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);
    var FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);
    var SizeStyle = Quill.import('attributors/style/size');
    Quill.register(SizeStyle, true);

  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {

    this.loadAll();
    this.topbarService.HotelSelectionChanged.subscribe(result=> {
      this.loadAll();
    });
    this.topbarService.SetTitleText(this.translate.instant('common.cust_email'))
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
    this.saveEmailData$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    //this.languages = this.languageService.loadAll();
    const subscriber = SessionStorage.SelectedSubscriber;
    const hotelId = SessionStorage.SelectedHotelId;

    const combinedSources = combineLatest({
      invitations: this.invitationService.LoadInvitations(subscriber, hotelId),
      languages : this.languageService.loadAll(subscriber,hotelId),
      branding: this.brandingService.LoadOnebySubscriber(subscriber,hotelId)
    }).pipe(takeUntil(this.destroy$)).subscribe( value => {
        this.invitations = value.invitations as InvitationDto[];
        // this.editedItem = this.invitations[0];
        this.branding = value.branding as BrandingDto;
        SessionStorage.Logo = this.branding.Logo;
        // this.fillForm(this.editedItem);
        // this.notiftyPreview();
        this.avaialableLanguages = value.languages as string[];
        this.avaialableLanguages.forEach(item=> {
          this.languages.push(AvailableLanguages.Languages.find( al=> al.lngCode == item)) 
        })
       
        this.editorForm.controls["Language"].setValue("hu")
        this.editedItem = this.invitations.find(item=>item.Lang == this.editorForm.controls["Language"].value);
        this.fillForm(this.editedItem)
      
        this.notiftyPreview();
    })
  }

  languageChanged() {
    this.editedItem = this.invitations.find(item=>item.Lang == this.editorForm.controls["Language"].value);
    this.fillForm(this.editedItem)
  }

  insertGuestName() {
    this.editor.quillEditor.insertText(this.currIndex, "#guestname#")
    this.notiftyPreview();
  }

  insertHotelName() {
    this.editor.quillEditor.insertText(this.currIndex, "#hotelname#")
    this.notiftyPreview();
  }
  insertLink() {
    this.editor.quillEditor.insertText(this.currIndex, "#invitation_link#")
    this.notiftyPreview();
  }

  insertLogo() {
    this.editor.quillEditor.insertText(this.currIndex, "#logo#")
    this.notiftyPreview();
  }
  editorChanged(event) {
    if( event.range) {
      this.currIndex = parseInt(event.range.index);
      this.isDirty = true;
    }
  }


  notiftyPreview() {
    this.editedItem.Body = this.editorForm.controls["Body"].value;
    this.customizeEmailService.emailDesignChanged.emit(this.editedItem)
  }

  isFormDirty()  {
    return this.editorForm.dirty
  }

 

  //#endregion =================================================================================================================
 


  submit() {
    //this.editedItem = this.invitations.find(item=>item.Lang == this.editorForm.controls["Language"].value);
    //this.fillForm(this.editedItem)
    const selectedLanguage  = this.editorForm.controls["Language"].value;
    this.invitations.find(item=>item.Lang == selectedLanguage).HotelId = SessionStorage.SelectedHotelId;
    this.invitations.find(item=>item.Lang == selectedLanguage).Subscriber = SessionStorage.SelectedSubscriber;
    this.invitations.find(item=>item.Lang == selectedLanguage).Body = this.editorForm.controls["Body"].value;
    this.invitations.find(item=>item.Lang == selectedLanguage).Lang = this.editorForm.controls["Language"].value;
    this.invitationService.Save(SessionStorage.SelectedSubscriber, SessionStorage.SelectedHotelId,this.invitations).subscribe({
      next:(result) => {
        this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        this.updateDataFromForm()
        this.editorForm.markAsUntouched();
        this.editorForm.markAsPristine();
      },
      error:(err: HttpErrorResponse) => {
        this.hwAlertService.error(this.translate.instant("common.save_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      }
    })
  }

 


  fillForm( data: InvitationDto) {
    this.editorForm.controls["Body"].setValue(data.Body)
    
    this.editorForm.controls["Language"].setValue(data.Lang)
  }

  updateDataFromForm() {
    this.invitations.find(item=>item.Lang == this.editorForm.controls["Language"].value).Body = this.editorForm.controls["Body"].value
    
  }



 
}
