import { UserServerDto } from "../dto/UserServer"
import { SelectItem } from "../interfaces/SelectItem"
import { AuthItem } from "../interfaces/auth-item"

export default class SessionStorage {

  public static set Hotels(hotels: SelectItem[]) {
    sessionStorage.setItem('hotels', JSON.stringify(hotels))
  }
  public static get Hotels(): SelectItem[] {
    return JSON.parse(sessionStorage.getItem('hotels')) as SelectItem[]
  }

  public static set SelectedHotel(hotel: string) {
    sessionStorage.setItem('selectedHotel', hotel)
  }

  public static get SelectedHotel() : string {
    return sessionStorage.getItem('selectedHotel')
  }
  public static get SelectedSubscriber(): string {
    const selectedHotel = sessionStorage.getItem('selectedHotel')
    if (selectedHotel) {
      const item = selectedHotel.split('@')[0]
      return item
    } else {
      return ''
    }
  }

  public static get SelectedHotelId(): number {
    const selectedHotel = sessionStorage.getItem('selectedHotel')
    if (selectedHotel) {
      const item = parseInt(selectedHotel.split('@')[1])
      return item
    } else {
      return 0
    }
  }

  public static set CoverImage(value: string) {
    sessionStorage.setItem('coverimage', value)
  }
  public static get CoverImage(): string {
    return sessionStorage.getItem('coverimage');
  }

  public static set Logo(value: string) {
    sessionStorage.setItem('logo', value)
  }
  public static get Logo(): string {
    return sessionStorage.getItem('logo');
  }

  public static set Email(value: string) {
    sessionStorage.setItem('email', value)
  }
  public static get Email(): string {
    return sessionStorage.getItem('email');
  }

  public static Clear() {
    sessionStorage.clear();
  }
  public static get HasExiLicence(): boolean {
    let ret: boolean;
    if (sessionStorage.getItem('userInfo')) {
      const ui = JSON.parse(sessionStorage.getItem('userInfo')) as UserServerDto
      ret = ui.SubscriberTree.SubsystemOptions[0].OptName.includes("EXI")
      return ret
    } else {
      return false;
    }

  }

  public static get HasExoLicence(): boolean {
    let ret: boolean;
    if (sessionStorage.getItem('userInfo')) {
      const ui = JSON.parse(sessionStorage.getItem('userInfo')) as UserServerDto
      ret = ui.SubscriberTree.SubsystemOptions[0].OptName.includes("EXO")
      return ret
    } else {
      return false;
    }
  }

  public static get IsTokenOk(): boolean {
    if (sessionStorage.getItem('hw-exi-backoffice-token')) {
      const token = JSON.parse(sessionStorage.getItem('hw-exi-backoffice-token')) as AuthItem;
      if (token.expires_in < new Date().getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }
}
