export default class AvailableLanguages {

  public static get Languages(): LngCodes[] {
    return [
    { lngCode: "hu", lngName: "Magyar" },
    { lngCode: "en", lngName: "English" },
    { lngCode: "de", lngName: "Deutsch" },
    { lngCode: "fr", lngName: "Français" },
    { lngCode: "ru", lngName: "Русский" },
    { lngCode: "ro", lngName: "Română" },
    { lngCode: "sk", lngName: "Slovenská" }
  ]
  }


}


export class LngCodes {
  lngCode: string;
  lngName: string;
}
//{lngCode:"hu",lngName: "Magyar"},
//     {lngCode:"en",lngName: "English"},
//     {lngCode:"de",lngName: "Deutsch"},
//     {lngCode:"fr",lngName: "Français"},
//     {lngCode:"ru",lngName: "Русский"},
//     {lngCode:"ro",lngName: "Română"},
