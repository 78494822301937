import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrandingService } from '../services/branding.service';
import { InvitationService } from '../services/invitation.service';
import { LanguageService } from '../services/language.service';
import { SubscriberParameterService } from '../services/SubscriberParameter.service';
import { Subscriber_ParameterService } from '../services/subscriber_parameter.service';

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [
   
  ]
})
export class FroexisharedModule {


  static forRoot(environment): ModuleWithProviders<FroexisharedModule> {
    // User envirnment here or decalare an injection token for the environment

    return {
      ngModule: FroexisharedModule,
      providers: [
        BrandingService, { provide: 'environment', useValue: environment },
        InvitationService, {provide: 'environment', useValue: environment},
        LanguageService, { provide: 'environment',useValue: environment},
        SubscriberParameterService, {provide: 'environment', useValue: environment},
        Subscriber_ParameterService, {provide: 'environment', useValue: environment}
      ]
    }; 
  }

}
