// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  white-space: pre-wrap !important;
}

@media (min-width: 1280px) and (max-width: 1454px) {
  button {
    white-space: pre-wrap !important;
    height: 50px !important;
    padding: 1em !important;
  }
  .mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
    font-size: 19px !important;
    height: 19px !important;
    width: 24px !important;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1pc3Npbmctc2VydmljZS10cmFuc2xhdGlvbnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxnQ0FBQTtBQUNKOztBQUVBO0VBQ0k7SUFDSSxnQ0FBQTtJQUNBLHVCQUFBO0lBQ0EsdUJBQUE7RUFDTjtFQUVFO0lBQ0ksMEJBQUE7SUFDQSx1QkFBQTtJQUNBLHNCQUFBO0VBQU47QUFDRiIsImZpbGUiOiJtaXNzaW5nLXNlcnZpY2UtdHJhbnNsYXRpb25zLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiYnV0dG9ue1xuICAgIHdoaXRlLXNwYWNlOiBwcmUtd3JhcCAhaW1wb3J0YW50O1xufVxuXG5AbWVkaWEgKG1pbi13aWR0aDogMTI4MHB4KSBhbmQgKG1heC13aWR0aDogMTQ1NHB4KSB7XG4gICAgYnV0dG9uIHtcbiAgICAgICAgd2hpdGUtc3BhY2U6IHByZS13cmFwICFpbXBvcnRhbnQ7XG4gICAgICAgIGhlaWdodDogNTBweCAhaW1wb3J0YW50O1xuICAgICAgICBwYWRkaW5nOiAxZW0gIWltcG9ydGFudDtcbiAgICB9XG5cbiAgICAubWF0LW1kYy11bmVsZXZhdGVkLWJ1dHRvbiA+IC5tYXQtaWNvbiwgLm1hdC1tZGMtcmFpc2VkLWJ1dHRvbiA+IC5tYXQtaWNvbiwgLm1hdC1tZGMtb3V0bGluZWQtYnV0dG9uID4gLm1hdC1pY29uIHtcbiAgICAgICAgZm9udC1zaXplOiAxOXB4ICFpbXBvcnRhbnQ7XG4gICAgICAgIGhlaWdodDogMTlweCAhaW1wb3J0YW50O1xuICAgICAgICB3aWR0aDogMjRweCAhaW1wb3J0YW50O1xuICAgIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/pages/dashboard/missing-service-translations/missing-service-translations.component.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI;IACI,gCAAA;IACA,uBAAA;IACA,uBAAA;EACN;EAEE;IACI,0BAAA;IACA,uBAAA;IACA,sBAAA;EAAN;AACF;AACA,4iCAA4iC","sourcesContent":["button{\n    white-space: pre-wrap !important;\n}\n\n@media (min-width: 1280px) and (max-width: 1454px) {\n    button {\n        white-space: pre-wrap !important;\n        height: 50px !important;\n        padding: 1em !important;\n    }\n\n    .mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {\n        font-size: 19px !important;\n        height: 19px !important;\n        width: 24px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
