import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiServer = environment.apiHost + environment.apiPort;
constructor(private http: HttpClient) {

 }

 getUserInfo( email : string) {
  return this.http.get(this.apiServer + '/api/GetRawUserDataByEmail/' + email );
}

}
