import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceEditable } from '../../../../../froexishared/src/dto/Service';
import { LanguageService } from '../../../../../froexishared/src/services/language.service';
@Component({
  selector: 'app-hw-services-dialog',
  templateUrl: './hw-services-dialog.component.html',
  styleUrls: ['./hw-services-dialog.component.scss']
})
export class HwServicesDialogComponent implements OnInit {

  editorForm: FormGroup;
  service: ServiceEditable
  constructor(
    private dialogRef: MatDialogRef<HwServicesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data : ServiceEditable,
    private fb: FormBuilder,
    private lngService : LanguageService
    
    ) {
      this.editorForm = fb.group({
       
      })
      this.service = data;
      const huItem = this.service.LangDescriptions.find(item=>item.Lang == "hu");
      const huIndex = this.service.LangDescriptions.findIndex(item=>item.Lang == "hu");
      this.service.LangDescriptions.splice(huIndex,1);
      this.service.LangDescriptions.splice(0,0,huItem);
      this.service.LangDescriptions.forEach(item=> {
        this.editorForm.addControl(item.Lang, new FormControl(item.ServiceDescription))
      })
      this.editorForm.controls["hu"].setValidators(Validators.required)
     }

  ngOnInit() {
    
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  submit() {
    this.data.LangDescriptions.forEach(item=> {
      item.ServiceDescription = this.editorForm.controls[item.Lang].value;
    })
    this.dialogRef.close(this.data)
  }
}
