// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.error-mark {
  font-size: 48px;
  color: red;
  top: 50%;
  height: 48px;
  left: 50%;
  width: 48px;
  vertical-align: middle;
}

.edit-button {
  font-size: 48px;
  top: 50%;
  height: 48px;
  left: 50%;
  width: 48px;
  vertical-align: middle;
}

.filter-label {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImh3LXNlcnZpY2VzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLFVBQUE7RUFDQSxRQUFBO0VBQ0EsWUFBQTtFQUNBLFNBQUE7RUFDQSxXQUFBO0VBQ0Esc0JBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7RUFDQyxRQUFBO0VBQ0QsWUFBQTtFQUNBLFNBQUE7RUFDQSxXQUFBO0VBQ0Esc0JBQUE7QUFDRjs7QUFDQTtFQUNFLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLFlBQUE7QUFFRiIsImZpbGUiOiJody1zZXJ2aWNlcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbInRhYmxlIHtcbiAgd2lkdGg6IDEwMCU7XG59XG5cbi5lcnJvci1tYXJrIHtcbiAgZm9udC1zaXplOiA0OHB4O1xuICBjb2xvcjogcmVkO1xuICB0b3A6IDUwJTtcbiAgaGVpZ2h0OiA0OHB4O1xuICBsZWZ0OiA1MCU7XG4gIHdpZHRoOiA0OHB4O1xuICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xufVxuXG4uZWRpdC1idXR0b24ge1xuICBmb250LXNpemU6IDQ4cHg7XG4gICB0b3A6IDUwJTtcbiAgaGVpZ2h0OiA0OHB4O1xuICBsZWZ0OiA1MCU7XG4gIHdpZHRoOiA0OHB4O1xuICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xufVxuLmZpbHRlci1sYWJlbCB7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgY29sb3I6IGJsYWNrO1xuICBcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/pages/hw-services/hw-services.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACC,QAAA;EACD,YAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,YAAA;AAEF;AACA,ogCAAogC","sourcesContent":["table {\n  width: 100%;\n}\n\n.error-mark {\n  font-size: 48px;\n  color: red;\n  top: 50%;\n  height: 48px;\n  left: 50%;\n  width: 48px;\n  vertical-align: middle;\n}\n\n.edit-button {\n  font-size: 48px;\n   top: 50%;\n  height: 48px;\n  left: 50%;\n  width: 48px;\n  vertical-align: middle;\n}\n.filter-label {\n  font-weight: 600;\n  font-size: 14px;\n  color: black;\n  \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
