import { Component, OnInit } from '@angular/core';
import SessionStorage from '@app/helpers/SessionStorage';

@Component({
  selector: 'invitations-tile',
  templateUrl: './invitations-tile.component.html',
  styleUrls: ['./invitations-tile.component.scss']
})
export class InvitationsTileComponent implements OnInit {
  hasExiLicence = SessionStorage.HasExiLicence
  hasExoLicence = SessionStorage.HasExoLicence
  constructor() { }

  ngOnInit() {
  }

}
