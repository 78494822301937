import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { AuthItem } from '@app/interfaces/auth-item';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  @Output() loginComplete = new EventEmitter<boolean>()
  private tokenPhrase = 'hw-exi-backoffice-token';
  private contentType = 'application/x-www-form-urlencoded';
  private unAuthorizedRequests: ((req: HttpRequest<any>) => boolean)[] = [];
  private differedRequests: { request: HttpRequest<any>, subject: Subject<any> }[] = [];
  
  constructor(
    private httpClient: HttpClient,
    private router:Router
  ) {
    super();
    this.unAuthorizedRequests = [
      this.isLoginRequest,
      this.isLogoutRequest,
      this.isRefreshRequest
    ];
  }


  public login(userName: string, password: string): Observable<AuthItem> {
    const headers = new HttpHeaders({ 'Content-Type': this.contentType, 'hw-action': 'access-token' });
    const options = { headers: headers };
    const data = { username: userName, password: password };
    const params: URLSearchParams = new URLSearchParams();
    Object.keys(data).forEach(key =>
      params.append(key, data[key])
    );
    return this.httpClient.post<AuthItem>(`${this.apiServer}${'/api/login'}`,
      params.toString(), { headers: headers }).pipe(map((response: AuthItem) => {
        this.saveToken(response);
        
        return response;
      }));
  }


  public getRawUserDataByEmail(email:string ) : Observable<any> {
    return this.httpClient.get(`${this.apiServer}${'/api/GetRawUserDataByEmail/'}${email}`)
    }
  public saveToken(token: AuthItem): void {
    const oldToken = <AuthItem>JSON.parse(sessionStorage.getItem(this.tokenPhrase));
    sessionStorage.setItem(this.tokenPhrase, JSON.stringify(<AuthItem>{
      access_token: token.access_token,
      refresh_token: token.refresh_token === undefined || token.refresh_token === null ? oldToken.refresh_token : token.refresh_token,
      next: this.getNextTimeout(<number>token.next),
      timeout: <number>token.next,
      expires_in: <number>token.expires_in,
      token_type:"Bearer"
    }));
  }
  public getNextTimeout(timeout: number): number {
    const now = new Date();
    return new Date(now.getTime() + (timeout / 2) * 1000).getTime();
  }

  public hasToken() {
    const token = sessionStorage.getItem(this.tokenPhrase);
    return token===null ? false:true
  }

  public isAuthTokenValid(): boolean {

    const token = this.getAuthToken();
    if (token.expires_in > new Date().getTime()) {
      return true;
    }
    else {
      return false;
    }
  }
  public isAuthTokenExpired(): boolean {
    const token = this.getAuthToken();
    if (!Boolean(token.next)) {
      return false;
    }
    return this.getAuthToken().next < new Date().getTime();
  }

  public getAuthToken(): AuthItem {
    const token = sessionStorage.getItem(this.tokenPhrase);
    if (!Boolean(token)) {
      return {}  as AuthItem;
    }
    return (JSON.parse(token) as AuthItem);
  }

  
  public get isLoggedIn(): boolean {
    const token = this.getAuthToken();
    if (!Boolean(token) || !Boolean(token.access_token)) {
      return false;
    }
    return token.next < new Date().getTime();
  }
  public isRefreshRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'refresh-token';
  }

  public isLoginRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'access-token';
  }

  public isLogoutRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'delete-token';
  }

  public isAthorizedRequest<T = any>(req: HttpRequest<T>) {
    const result = !this.unAuthorizedRequests.some(u => u(req));
    return result;
  }
  public clearDifferedRequests() {
    this.differedRequests = [];
  }

  public setLoginComplete(completed: boolean) {
    this.loginComplete.emit(completed);
  }

  
}
