import { Component } from '@angular/core';
import { TopbarService } from '@app/services/topbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent1 {
  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
  ) {
    this.topBarService.SetTitleText(this.translate.instant('chat.title'))
  }
}
