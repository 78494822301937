// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-style {
  border-radius: 5px;
  padding: 3em 1em 3em 1em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVtYWlsLXByZXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDSSxrQkFBQTtFQUVBLHdCQUFBO0FBREoiLCJmaWxlIjoiZW1haWwtcHJldmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLnByZXZpZXctc3R5bGUge1xuICAgIGJvcmRlci1yYWRpdXM6IDVweDtcbiAgICAvLyBib3gtc2hhZG93OiAwIDVweCAxMHB4IHJnYmEoMCwgMCwgMCwgMC4yNSk7XG4gICAgcGFkZGluZzogM2VtIDFlbSAzZW0gMWVtO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/pages/customize-email/email-preview/email-preview.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EAEA,wBAAA;AADJ;AACA,wcAAwc","sourcesContent":["\n.preview-style {\n    border-radius: 5px;\n    // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);\n    padding: 3em 1em 3em 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
