import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DesignComponent } from './pages/design/design.component';
import { CustomizeEmailComponent } from './pages/customize-email/customize-email.component';
import { CheckInDataSettingsComponent } from './pages/check-in-data-settings/check-in-data-settings.component';
import { PaymentSettingsComponent } from './pages/payment-settings/payment-settings.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { IdentityVerificationComponent } from './pages/identity-verification/identity-verification.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HwServicesComponent } from './pages/hw-services/hw-services.component';
import { CanDeactivateGuard } from './guards/can-deactivate-guard';
import { ChatComponent1 } from './pages/chat/chat.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'design', component: DesignComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'customizeemail', component: CustomizeEmailComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'checkindatasettings', component: CheckInDataSettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'paymentsettings', component: PaymentSettingsComponent, canActivate: [AuthGuard] },
  { path: 'profilesettings', component: ProfileSettingsComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'identityverification', component: IdentityVerificationComponent, canActivate: [AuthGuard] },
  { path: 'services', component: HwServicesComponent, canActivate: [AuthGuard] },
  { path: 'chat', component: ChatComponent1, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
