
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SubscriberParameter } from '../dto/SubscriberParameter';


@Injectable()
export class SubscriberParameterService {
  private environment
  constructor(private http: HttpClient, @Inject('environment') environment) { 
    this.environment = environment
  }

  loadAvailableGuestDataFields() {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get( apiServer +  '/api/exi/subscriberparameter/LoadGuestDataFields/')
  }

  loadAll( subscriberCode: string, hotelId: number) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get(apiServer + '/api/exi/subscriberparameter/LoadAll/' + subscriberCode + "/" + hotelId);   
  }

  loadByTypeCode(subscriberCode: string, hotelId: number, typeCode: string) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get(apiServer + '/api/exi/subscriberparameter/LoadByTypeCode/' + subscriberCode + "/" + hotelId + '/' + typeCode); 
  }
  add(data : SubscriberParameter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.post(apiServer + '/api/exi/subscriberparameter/Add', data, { headers });
  }

  update(data : SubscriberParameter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.post(apiServer + '/api/exi/subscriberparameter/Update', data, { headers });
  }

  delete(id : number) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.delete(apiServer + '/api/exi/subscriberparameter/Delete/' + id);
  }

  updateAll( data : SubscriberParameter[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.post(apiServer + '/api/exi/subscriberparameter/UpdateAll', data, { headers });
  }
}
