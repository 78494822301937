import { Pipe, PipeTransform } from '@angular/core';
import  { CurrencySettings} from '../../../../froexishared/src/dto/CurrencySettingsDto'
@Pipe({
  name: 'CurrencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor( ) {}

  transform(value: number,  settings: CurrencySettings): any {
    if( !value) {
      return "0";
    }
    let format: string;
    switch (settings.CurrencyCode) {
      case "EUR":
        format = "de-DE"
        break;
      case "HUF":
        format = "hu-HU"
        break;
    }

    const ret = new Intl.NumberFormat(format).format(value)
    return ret;
   
  }

}


/*
    
    if( settings.CurrencyCode == "HUF") {}
    let thousendSeparator: string = " ";
    let decimalSeparator : string = settings.DecimalSeparator;
    let numValue: number = value;
    if( settings.NumberOfDecimals == 0 ) {
      numValue = Math.round(numValue);
    }

    if( numValue === undefined || numValue === null) {
      return `${'?'}`;
    }
    
    const negativeSign : string = numValue.toString().substring(0,1) === "-" ? "-" : "";
    const positiveSign : string = numValue > 0 && !supressPlusSign ? "+": "";
    let remainder: number;
   
    if( numValue == 0 ) {
      if(  settings.NumberOfDecimals == 0 ) {
        return "0";
      } else {
        return "0" +  decimalSeparator +  "00";
      }
      
    }

    const arr = Math.abs(numValue - ( parseInt(numValue.toString()))).toString().split('.');
    if( arr.length === 2) {
      remainder =  parseInt(arr[1]);
    } 
    else {
      remainder = 0;
    }
    
    let  currencyAsString : string = Math.abs(parseInt(numValue.toString())).toString();
    let len = currencyAsString.length;
    var strRet = '';
    var fraction = '';

    var j=0  ;
    for( var i=len; i>-1;i--) {
      if( (j%3  === 0) && ( j!==0)) {
        if(i!==0) {
          strRet =  thousendSeparator + currencyAsString.substring(i,1) + strRet;
        }
        else {
          strRet =   currencyAsString.substring(i,1) + strRet;
        }
      }
      else {
        strRet =  currencyAsString.substring(i,1) + strRet;
      }
      j++;

      if( settings.NumberOfDecimals > 0 ) {
        switch ( remainder.toString().length) {
          case 0:
            fraction = "00";
            break;
          case 1:
            fraction = remainder.toString() + "0"; 
            break;
          default:
            fraction = remainder.toString().substring(0,2)
            break;
        }
      } else {
        fraction = "";
        decimalSeparator = "";
      }
 
    }

    if( numValue === 0 ) {
      return strRet + decimalSeparator + fraction;
    }
    
    if( numValue < 0) {
      return negativeSign + strRet + decimalSeparator + fraction;
    }
    else {
      return positiveSign + strRet + decimalSeparator + fraction
    }

*/
