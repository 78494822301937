

// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// 3rdparty
import { Subscription } from 'rxjs';
// Hostware
import { HwServicesService } from '../../../../../../froexishared/src/services/hw-services.service';
import { ServiceEditable } from '../../../../../../froexishared/src/dto/Service';
import SessionStorage from '@app/helpers/SessionStorage';

@Component({
  selector: 'missing-service-translations',
  templateUrl: './missing-service-translations.component.html',
  styleUrls: ['./missing-service-translations.component.scss']
})
export class MissingServiceTranslationsComponent implements OnInit, OnDestroy {

  loading: boolean;
  getTranslations$: Subscription;
  services: ServiceEditable[] = [];
  missingCounter: number;

  constructor(private hwServicesService: HwServicesService) { }
  ngOnDestroy(): void {

  }


  //#region =============== Angular Lifecyle events ============================================================================
  //#endregion =================================================================================================================

  ngOnInit() {
    this.loading = true;
    let subs = SessionStorage.SelectedSubscriber;
    let hotelId = SessionStorage.SelectedHotelId;
    this.getTranslations$ = this.hwServicesService.getServices(subs, hotelId).subscribe({
      next: (result) => {
        this.services = result as ServiceEditable[]
        this.calculateMissingItems();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      }
    })
  }


  calculateMissingItems() {
    this.missingCounter = 0;
    this.services.forEach(item => {
      const found = item.LangDescriptions.find(item => item.ServiceDescription === 'null');
      if (found === undefined) {
        this.missingCounter += 1;
      }
    })
    //missingCounter
  }
}

   