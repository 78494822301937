import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { InvitationService } from '../../../../../../froexishared/src/services/invitation.service';
import { InvitationDto } from '../../../../../../froexishared/src/dto/InvitationDto';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import SessionStorage from '@app/helpers/SessionStorage';
import { CustomizeEmailService } from '@app/services/customize-email.service';
import * as Quill from "quill";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit, AfterViewInit {

  replacedBody: string;

  @Input() invitation: InvitationDto

  @ViewChild('viewer') viewer: QuillViewHTMLComponent

  viewForm: FormGroup;

  visitButton: string;
  constructor(
    private customizeEmailService: CustomizeEmailService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {

   
    this.viewForm = fb.group({
      "Body": new FormControl(this.invitation?.Body)
    })
  }

  ngOnInit() {
    // get body
    let lines: string[] = [];

    this.customizeEmailService.emailDesignChanged.subscribe(data => {
      this.replacedBody = (data as InvitationDto).Body;
      this.replacedBody = this.replaceAll(this.replacedBody,"#hotelname#", SessionStorage.SelectedSubscriber)
      this.replacedBody = this.replaceAll(this.replacedBody,"#guestname#", "John Doe")
      this.replacedBody = this.replaceAll(this.replacedBody,"#invitation_link#", this.buildLogoStyle())
      this.replacedBody =this.replaceAll(this.replacedBody,"#logo#", "<img src='" + SessionStorage.Logo + "'>")
    })
  }
  ngAfterViewInit(): void {
    // let oldBody = this.invitation?.Body;
    // if(oldBody) {
    //   oldBody.replace("#hotelname#", SessionStorage.SelectedSubscriber)
    //   oldBody.replace("#guesname#", "John Doe")
    //   oldBody.replace("#invitation_link#", "https://froexi.hostware.cloud/?guestStayUuid=ce4a7ec1-ae2a-4788-83a7-e18d39db2281&Hotel3pId=4bfe55ac-1634-481a-a2c6-f0d222e47c33")

    // }
  }


  /*
  <a href="google.com style="font-weight: 500; color: white; font-size: 20px; background-color: #555555; padding:  0.5em 1em 0.5em 1em; border-radius: 30px; text-decoration: none; display: inline-block; border: 1px solid darkgray;">
    Példa Link
  </a>
  */


  buildLogoStyle(): string {

  //  return('<a href="https://www.example.com" style="font-weight: 500; color: white; font-size: 20px; background-color: #555555; padding:  0.5em 1em 0.5em 1em; border-radius: 30px; text-decoration: none; display: inline-block; border: 1px solid darkgray;">Példa Link</a>')
 
    let st: string = '';
    st+= '<a href="https://www.example.com" style="font-weight: 500; color: white; font-size: 20px; background-color: #555555; padding:  0.5em 1em 0.5em 1em; border-radius: 30px; text-decoration: none; display: inline-block; border: 1px solid darkgray;">'
    st += this.translate.instant('common.check_in')
    st+="</a>"
    return st;

    
  }
  // buildLogoStyle(): string {
  //   let st: string = '';
  //   st = '<a href="google.com" >';
  //   st += "<span "
  //   st += 'style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);'
  //   st += " width: 200px;"
  //   st += " text-align: center;"
  //   st += " margin-top: 2em;"
  //   st += " margin-bottom: 3em;"
  //   st += " box-sizing: border-box;"
  //   st += " position: relative;"
  //   st += " -webkit-user-select: none;"
  //   st += " user-select: none;"
  //   st += " cursor: pointer;"
  //   st += " outline: none;"
  //   st += " border: none;"
  //   st += " -webkit-tap-highlight-color: transparent;"
  //   st += " display: inline-block;"
  //   st += " white-space: nowrap;"
  //   st += " text-decoration: none;"
  //   st += " vertical-align: baseline;"
  //   st += " text-align: center;"
  //   st += " margin: 0;"
  //   st += " min-width: 64px;"
  //   st += " line-height: 36px;"
  //   st += " padding: 0 16px;"
  //   st += " border-radius: 23px;"
  //   st += " overflow: visible;"
  //   st += " background-image: linear-gradient(#686868, #424242);"
  //   st += " color: white !important;"
  //   st += " border-style: solid !important;"
  //   st += " border-color: #565656 !important;"
  //   st += " border-width: 2px !important;"
  //   st += " font-size: 14px !important;"
  //   st += ' font-weight: 600 !important;"'
  //   st += ">"
  //   st += this.translate.instant('common.check_in')
  //   st += "</span>"
  //   st += "</a>"
  //   return st;
  // }
  replaceAll(sourceString : string, from : string, to: string) {
    return sourceString.split(from).join(to)
  }
}
