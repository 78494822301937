import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoModule } from 'ngx-socket-io';
import { ChatComponent } from './chat.component';
import { ChatService } from './chat.service';
import { YesNoDialogComponent } from './dialog/yes-no-dialog/yes-no-dialog.component';
import { SharedModule } from './shared.module';

@NgModule({
  declarations: [
    ChatComponent,
    YesNoDialogComponent
  ],
  imports: [
    SharedModule,
    SocketIoModule.forRoot({ url: '', options: { autoConnect: false } }),
    TranslateModule
  ],
  exports: [
    ChatComponent
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },

  ]
})

export class ChatModule {
  static forRoot(environment): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [
        ChatService, { provide: 'environment', useValue: environment },
      ]
    };
  }
}


