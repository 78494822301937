import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BrandingDto } from '../dto/BrandingDto';

@Injectable()
export class BrandingService {

  private environment
  constructor(private http: HttpClient, @Inject('environment') environment) { 
    this.environment = environment
  }


  LoadOnebySubscriber(Subscriber : string, hotelId : number) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get( apiServer +  '/api/exi/branding/LoadOnebySubscriber/'+ Subscriber + "/" + hotelId)
  }
  Add( data: BrandingDto) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( apiServer +  '/api/exi/branding/Add',data,httpOptions)
  }

  Update( data: BrandingDto) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( apiServer +  '/api/exi/branding/Update',data,httpOptions)
  }

  Delete( Subscriber : string, hotelId : number) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.delete( apiServer +  '/api/exi/branding/delete/'+ Subscriber + "/" + hotelId)
  }
}
