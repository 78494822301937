import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  constructor() { }

  @Output() HotelSelectionChanged = new EventEmitter<string>()
  @Output() TitleTextChanged = new EventEmitter<string>()


  SetTitleText(newText: string) {
    this.TitleTextChanged.emit(newText)
  }

  SetHotelSelectionChanged(newHotel : string) {
    this.HotelSelectionChanged.emit(newHotel)
  }
}
