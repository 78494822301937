import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  GetDashboardData() {
    let apiServer = environment.apiHost + environment.apiPort;
    return this.http.get( apiServer +  '/api/exi/dashboard/getdashboarddata')

  }
}
