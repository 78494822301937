// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  margin-left: 5.6rem;
}

mat-card {
  margin: 1.5rem;
  padding: 1rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxtQkFBQTtBQUNKOztBQUVBO0VBQ0ksY0FBQTtFQUNBLGFBQUE7QUFDSiIsImZpbGUiOiJjaGF0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNoYXQtY29udGFpbmVyIHtcbiAgICBtYXJnaW4tbGVmdDogNS42cmVtO1xufVxuXG5tYXQtY2FyZCB7XG4gICAgbWFyZ2luOiAxLjVyZW07XG4gICAgcGFkZGluZzogMXJlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/pages/chat/chat.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,aAAA;AACJ;AACA,waAAwa","sourcesContent":[".chat-container {\n    margin-left: 5.6rem;\n}\n\nmat-card {\n    margin: 1.5rem;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
