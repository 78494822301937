import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ChatService {
  @Output() customerFacingDataLength: EventEmitter<number> = new EventEmitter<number>()
  constructor() {

  }

  sendChatDataToCustomerFacing(chatLenght: number) {
    this.customerFacingDataLength.emit(chatLenght)
  }

}
