import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InvitationDto } from '../dto/InvitationDto';

@Injectable()
export class InvitationService {
  private environment

  constructor(private http: HttpClient, @Inject('environment') environment) {
    this.environment = environment
  }

  LoadInvitations(Subscriber: string, hotelId: number) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get(apiServer + '/api/exi/invitation/loadinvitations/' + Subscriber + "/" + hotelId)
  }

  Add(data: InvitationDto) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( apiServer +  '/api/exi/invitation/Add',data,httpOptions)
  }

  Update(data: InvitationDto) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( apiServer +  '/api/exi/invitation/Update',data,httpOptions)
  }
  
  Save( Subscriber : string, hotelId : number, data: InvitationDto[]) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post( apiServer +  '/api/exi/invitation/SaveInvitations/'+ Subscriber + "/" + hotelId, data,httpOptions)
  }

}
