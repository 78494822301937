import { EventEmitter, Injectable, Output } from '@angular/core';
import { InvitationDto } from '../../../../froexishared/src/dto/InvitationDto';

@Injectable({
  providedIn: 'root'
})
export class CustomizeEmailService {

  constructor() { }

  @Output() emailDesignChanged = new EventEmitter<InvitationDto>()

  changeEmailDesign(inv: InvitationDto) {
    this.emailDesignChanged.emit(inv);
  }


}
