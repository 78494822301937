// Angular 
import { Component, OnInit,OnDestroy } from '@angular/core';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { Subscriber_Parameter } from '../../../../../froexishared/src/dto/Subscriber_Parameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { Subscriber_ParameterService } from '../../../../../froexishared/src/services/subscriber_parameter.service';
import { SessionStorageService } from '@app/services/session-storage.service';
import  SessionStorage from '@app/helpers/SessionStorage'
import {GuestDataFields}  from '../../../../../froexishared/src/dto/GuestdataFields'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { CanComponentDeactivate } from '@app/interfaces/CanComponentDeactivate';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
@Component({
  selector: 'app-check-in-data-settings',
  templateUrl: './check-in-data-settings.component.html',
  styleUrls: ['./check-in-data-settings.component.scss']
})

export class CheckInDataSettingsComponent implements OnInit {

  availableCheckinDataFields: GuestDataFields[]=[];
  editorList: GuestDataFields[]=[];
  editorForm: FormGroup;
  editedRecord : SubscriberParameter;
  loadAvailableGuestDataFieldsSync$ : Subscription;
  loadGuestDataFields$ : Subscription;
  addGuestDataFields$: Subscription;
  updateGuestDataFields$ : Subscription;
  loaded: boolean;
  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
    private subscriberParameterService : SubscriberParameterService,
    private subscriber_ParameterService : Subscriber_ParameterService,
    private hwAlertService: HwAlertService,
    private confirmService : HwConfirmationDialogService,
    private fb: FormBuilder
  
  ) {
    this.editorForm = this.fb.group({});
   }


  //#region =============== Angular Lifecyle events ============================================================================
   ngOnInit(): void {
    
    this.topBarService.SetTitleText(this.translate.instant('common.check_in_data'))
    this.topBarService.HotelSelectionChanged.subscribe(result=> {
      this.loadAll()
    })
    this.loadAll();
  }
  
  ngOnDestroy(): void {
    this.loadAvailableGuestDataFieldsSync$?.unsubscribe();
    this.addGuestDataFields$?.unsubscribe();
    this.updateGuestDataFields$?.unsubscribe();
    this.loadGuestDataFields$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  
  async loadAll() {
   
    const availableParams  = await firstValueFrom(this.subscriberParameterService.loadAvailableGuestDataFields());
    this.availableCheckinDataFields = availableParams as GuestDataFields[];
    this.loadGuestDataFields$ = this.subscriberParameterService.loadByTypeCode(SessionStorage.SelectedSubscriber,SessionStorage.SelectedHotelId,"DATA_FORM").subscribe( result=>{
      const record = result as SubscriberParameter;
      this.editedRecord = record;
      this.editorList = JSON.parse(record.Param_Json_Value)  as GuestDataFields[];
      this.editorForm = this.fb.group({}); // clear form  items
      this.editorList.forEach(item=> {
        this.editorForm.addControl(item.DataCode, new FormControl(item.DataNature))
      })
      this.loaded = true;
     })
   }


  resolveDataCode( dataCode: string) {
    switch (dataCode)  {
      case "SURNAME":
        return this.translate.instant("checkin_datasettings.surname")
      case "GIVEN_NAMES":
        return this.translate.instant("checkin_datasettings.givennames")
      case "BIRTH_SURNAME":
        return this.translate.instant("checkin_datasettings.birth_surname")
      case "BIRTH_GIVENNAMES":
        return this.translate.instant("checkin_datasettings.birth_givennames")
      case "BIRTH_PLACE":
        return this.translate.instant("checkin_datasettings.birthplace")
      case "BIRTH_DATE":
        return this.translate.instant("checkin_datasettings.birthdate")
      case "NATIONALITY":
        return this.translate.instant("checkin_datasettings.nationality")
      case "SEX":
        return this.translate.instant("checkin_datasettings.sex")
      case "MOTHER_SURNAME":
        return this.translate.instant("checkin_datasettings.mother_surname")
      case "MOTHER_GIVENNAMES":
        return this.translate.instant("checkin_datasettings.mother_givennames")
      case "ID_TYPE":
        return this.translate.instant("checkin_datasettings.id_type")
      case "ID_NUMBER":
        return this.translate.instant("checkin_datasettings.id_number")
      case "VISA_NUMBER":
        return this.translate.instant("checkin_datasettings.visa_number")
      case "ENTRY_PLACE":
        return this.translate.instant("checkin_datasettings.place_of_entry")
      case "ENTRY_DATE":
        return this.translate.instant("checkin_datasettings.date_of_entry")
      case "EMAIL":
        return this.translate.instant("checkin_datasettings.email")
      case "PHONE":
        return this.translate.instant("checkin_datasettings.phonenumber")
      case "COUNTRY":
        return this.translate.instant("checkin_datasettings.country")
      case "ZIPCODE":
        return this.translate.instant("checkin_datasettings.zip_code")
      case "CITY":
        return this.translate.instant("checkin_datasettings.city")
      case "ADDRESS":
        return this.translate.instant("checkin_datasettings.address")
      case "PLATE_NUMBER":
        return this.translate.instant("checkin_datasettings.plate_number")
      case "SIGNATURE":
        return this.translate.instant("checkin_datasettings.signature")
      default:
        return "Hello bello " + dataCode; 
    }
  }


  //#endregion =================================================================================================================


  submit(): void {
    this.editorList.forEach(item=> {
      item.DataNature = this.editorForm.controls[item.DataCode].value;
    })

    const jsonField = JSON.stringify(this.editorList);
    this.editedRecord.Param_Json_Value = jsonField;
    try {
      this.updateGuestDataFields$ =  this.subscriberParameterService.update(this.editedRecord).subscribe( result=> {
        this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        this.editorForm.markAsUntouched();
        this.editorForm.markAsPristine();
      })
  
    } catch (ex) {
      this.hwAlertService.error(this.translate.instant("common.save_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }


  isFormDirty()  {
    return this.editorForm.dirty
  }
}

