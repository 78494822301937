// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.sidebar {
  left: 0;
  height: 100%;
}

.outlet {
  margin-left: 61px;
  margin-top: 64px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGtCQUFBO0VBQ0EsT0FBQTtFQUNBLE1BQUE7RUFDQSxXQUFBO0FBQUY7O0FBR0E7RUFFRSxPQUFBO0VBRUEsWUFBQTtBQUZGOztBQUtBO0VBQ0MsaUJBQUE7RUFDQSxnQkFBQTtBQUZEIiwiZmlsZSI6ImFwcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuLnRvcGJhciB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgbGVmdDowO1xuICB0b3A6MDtcbiAgd2lkdGg6IDEwMCU7XG4gIC8vIGhlaWdodDogNDBweDs7XG59XG4uc2lkZWJhciB7XG4gIC8vIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgbGVmdCA6IDA7XG4gIC8vIHRvcDogNTE7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuLm91dGxldCB7XG4gbWFyZ2luLWxlZnQ6IDYxcHg7XG4gbWFyZ2luLXRvcDogNjRweDtcbn1cblxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/app.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;AAAF;;AAGA;EAEE,OAAA;EAEA,YAAA;AAFF;;AAKA;EACC,iBAAA;EACA,gBAAA;AAFD;AACA,grBAAgrB","sourcesContent":["\n.topbar {\n  position: absolute;\n  left:0;\n  top:0;\n  width: 100%;\n  // height: 40px;;\n}\n.sidebar {\n  // position: absolute;\n  left : 0;\n  // top: 51;\n  height: 100%;\n}\n\n.outlet {\n margin-left: 61px;\n margin-top: 64px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
