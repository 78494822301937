import { Component, OnInit } from '@angular/core';
import SessionStorage from '@app/helpers/SessionStorage';
import { AuthService } from '@app/services/auth.service';
// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

  name: string;
  eMail: string;
  subscriber : string;
  hotelName: string;

  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
    private authService : AuthService
  ) { }
  
  ngOnInit(): void { 
    this.topBarService.SetTitleText(this.translate.instant('common.profile'))
    this.loadAll();
    this.topBarService.HotelSelectionChanged.subscribe(result=> {
      this.loadAll();
    })
  }

  submit() {
    
  }
   
  loadAll() {
    const ui =  SessionStorage.Email;
    this.authService.getRawUserDataByEmail(SessionStorage.Email).subscribe(result=> {

      this.name= result["Name"];
      this.eMail = SessionStorage.Email;
      this.subscriber= SessionStorage.SelectedSubscriber;
      const hotelArr = SessionStorage.Hotels;
      const selHot = SessionStorage.SelectedHotel;

      this.hotelName= hotelArr.find(item=>item.value === selHot).label //SessionStorage.Hotels.find(item=>item.value == SessionStorage.SelectedHotel).title
   
    })
  }
}
