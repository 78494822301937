import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { ServiceEditable } from '../dto/Service'


@Injectable({ providedIn: 'root' })
export class HwServicesService {
  private environment

  constructor(private http: HttpClient,  @Inject('environment') environment) { 
    this.environment = environment;
  }


  getServiceGroups(Subscriber: string, HotelId: number, language: string) {
    return this.http.get(this.environment.exiBackendUrl + "/api/exi/service/getservicegroups/" + Subscriber + "/" + HotelId + "/" + language)
  }

  getServices(Subscriber: string, HotelId: number) {
    return this.http.get(this.environment.exiBackendUrl + "/api/exi/service/getservices/" + Subscriber + "/" + HotelId )
  }

  saveService(data: ServiceEditable) {
    const headers = new HttpHeaders().set("Content-Type","application/json");
    return this.http.post(this.environment.exiBackendUrl + "/api/exi/service/saveservice" ,data,{headers})
  }
}
