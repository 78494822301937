import { EventEmitter, Injectable, Output } from '@angular/core';
import { BrandingDto } from '../../../../froexishared/src/dto/BrandingDto';

@Injectable({
  providedIn: 'root'
})
export class ImagePreviewService {

  constructor() { }

  @Output() designChanged = new EventEmitter<BrandingDto>()

  changeDesign(data: BrandingDto) {
    this.designChanged.emit(data)
  }
}
