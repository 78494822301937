// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest, takeUntil ,Subject} from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';

// Hostware
import { HwServicesService } from '../../../../../froexishared/src/services/hw-services.service';
import SessionStorage from '@app/helpers/SessionStorage';
import { Service, ServiceGroup, ServiceList, ServiceEditable } from '../../../../../froexishared/src/dto/Service';
import { TopbarService } from '@app/services/topbar.service';
import { HwServicesDialogComponent } from '@app/dialogs/hw-services-dialog/hw-services-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';


@AutoUnsubscribe()
@Component({
  selector: 'hw-services',
  templateUrl: './hw-services.component.html',
  styleUrls: ['./hw-services.component.scss']
})
export class HwServicesComponent implements OnInit, OnDestroy {
  
  getServiceGroups$ : Subscription
  getService$ :  Subscription;
  saveService$: Subscription;
  listForm: FormGroup;
  groups: ServiceGroup[]=[];
  services: ServiceEditable[]=[];
  dataSource :MatTableDataSource<ServiceEditable>;

  tableColumns: string[]=["ServiceId","Description","ServiceGroup","Action"];

  filterDictionary = new Map<string,string>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private hwAlertService: HwAlertService,
    private hwServicesService: HwServicesService,
    private translate: TranslateService,
    private topbarService: TopbarService,
    private hwServicesDialog: MatDialog,

    private fb: FormBuilder
    ) {
      this.listForm = fb.group({
        ServiceId: '',
        Description : "" ,
        ServiceGroup : "" 
      })
     }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() : void{
    this.topbarService.SetTitleText(this.translate.instant('services.services'))
    this.filterDictionary.set('ServiceId', '')
    this.filterDictionary.set('Description', '')
    this.filterDictionary.set('ServiceGroup', '')
   
    this.topbarService.HotelSelectionChanged.subscribe(result=> {
      this.loadAll();
    })
    this.loadAll();
  }
 
  ngOnDestroy(): void {
    this.getService$?.unsubscribe();
    this.getServiceGroups$?.unsubscribe();
    this.saveService$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  applyFilter(field:string, fieldValue: string) {
     
    this.dataSource.filterPredicate = ( data : ServiceEditable, filterValue: string )=> {
      return  data[field].toLowerCase().startsWith(filterValue.toLowerCase())
    }
    this.dataSource.filter = fieldValue.toLowerCase();
  }

  editRecord( editableItem: ServiceEditable) {
    const record = this.services.find(item=>item.ServiceId == editableItem.ServiceId)
    const serviceDialogRef  = this.hwServicesDialog.open(HwServicesDialogComponent, {
      disableClose : true,
      width: "60vw",
      data: record
    })

    serviceDialogRef.afterClosed().subscribe( data=> {
      if( data) {
        this.saveService$ = this.hwServicesService.saveService(data).subscribe(
          {
            next: (result) =>  {
              const index = this.services.findIndex(item=>item.ServiceId);
              this.services[index]= data as ServiceEditable;
              this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));

            },
            error: (err: HttpErrorResponse) => {
              this.hwAlertService.error(this.translate.instant("common.save_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

            } 
          }
        )
      }
    })

  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    let  subs    = SessionStorage.SelectedSubscriber;
    let  hotelId = SessionStorage.SelectedHotelId;
    const combinedSourced = combineLatest({
      // serviceGroups: this.hwServicesService.getServiceGroups(subs,hotelId,'hu'),
      services: this.hwServicesService.getServices(subs,hotelId)
    }).pipe(takeUntil(this.destroy$)).subscribe(result=> {
      // this.groups = result.serviceGroups as ServiceGroup[];
      this.services = result.services as ServiceEditable[];
      this.dataSource = new MatTableDataSource(this.services)
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const filterData = JSON.parse(filter) as Map<string,string>;
        let rowOk: boolean = true;
        for ( let[key,value] of filterData) {
          const found = (data[key].toString().startsWith(value)) || (value == '') || (value == null)
          if(!found) {
            rowOk = false;
          } 
        }
        return rowOk
      }
    })
  }

  hasMissingLanguage(editableItem: ServiceEditable) : boolean {
    
     const item =  editableItem.LangDescriptions.find( item=> item.ServiceDescription === null );
     if(item) {
      return true
     } else {
      return false;
     }
  }
 
  
  //#endregion =================================================================================================================

}


