import { Component, OnInit } from '@angular/core';
// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: ['./identity-verification.component.scss']
})
export class IdentityVerificationComponent implements OnInit {

  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void { 
    this.topBarService.SetTitleText(this.translate.instant('common.identity_verification'))
  }

  submit() {
    
  }
}
