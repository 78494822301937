import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImagePreviewService } from '@app/services/image-preview.service';
import { BrandingDto } from '../../../../../../froexishared/src/dto/BrandingDto';

@Component({
  selector: 'design-preview',
  templateUrl: './design-preview.component.html',
  styleUrls: ['./design-preview.component.scss']
})
export class DesignPreviewComponent implements OnInit,AfterViewInit {
  
  previewContext: CanvasRenderingContext2D;
  branding : BrandingDto = new BrandingDto();
  logoImage = new Image();
  constructor(
    private imagePreviewService : ImagePreviewService,
    private cd : ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.imagePreviewService.designChanged.subscribe(  data=> {
      this.branding = data ;
 
       this.cd.detectChanges();
    })
  }

  ngAfterViewInit(): void {
   
  }


  fontStyle() {
    let styles = {};
    styles["font-family"]=this.branding.FontName;
   
    return styles;
  }

  footerStyle() {
    let styles = {};
    styles["padding"]="0px 15px -1px 15px";
    styles["width"] = "100%"
    styles["background-color"]=this.branding.PrimaryColor;
    styles["height"] = "35px"
    styles["border-radius"] = "10px 10px 0 0";
    styles["margin-top"] = "10em";
    return styles;
  }

  headerStyle() {
    let styles = {};
    styles["background-color"]=this.branding.PrimaryColor;
    return styles;
  }
  footerTextStyle() {
    let styles = {};
     styles["color"]=this.branding.SecondaryColor;
    return styles;
  
  }


}
