import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';



@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authService: AuthService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth();
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.auth();
  }

  
  private auth(): boolean {
    if ((!this.authService.hasToken()) || (!this.authService.isAuthTokenValid())) {
      this.router.navigate(["login"]);
      return false;
    }
    else {
      return true;
    }
  }
}

