import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable , Inject} from '@angular/core';
import { SubscriberDto } from '@app/dto/SubsciberDto';

@Injectable({providedIn:"root"})
export class SubscriberService {

private environment
constructor(private http: HttpClient, @Inject('environment') environment) {
  this.environment = environment;
 }

  LoadByHotel( Subscriber : string, HotelId: number  ) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get( apiServer +  '/api/exi/subscribers/LoadByHotel/'+ Subscriber + "/" + HotelId)
  }
  Update( data : SubscriberDto) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put( apiServer +  '/api/exi/subscribers/Update',data,httpOptions)
  }
}
