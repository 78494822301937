import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public apiServer = environment.apiHost + environment.apiPort;
  constructor() { }

}
