import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import SessionStorage from '@app/helpers/SessionStorage';
// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  hasExiLicence = SessionStorage.HasExiLicence
  hasExoLicence = SessionStorage.HasExoLicence
  
  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
    
  ) { }

  ngOnInit(): void { 
   this.topBarService.SetTitleText(this.translate.instant('common.dashboard'))
   this.topBarService.HotelSelectionChanged.subscribe(result=>{
    this.loadAll();
   })
  }

  loadAll() {
    
  }
}
