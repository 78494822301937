export const environment = {
  production: false,
  apiHost: 'https://froexi.hostware.cloud',
  apiPort: '',
  httpHost: 'https://froexi.hostware.cloud',
  home: true,
  exiBackendUrl: "https://froexi.hostware.cloud",
  chatBackendUrl: 'https://cht.hostwaremobile.com'
};


