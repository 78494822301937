import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/hu';
import 'dayjs/locale/en';
import { AuthService } from './services/auth.service';
import SessionStorage from './helpers/SessionStorage';
import { Socket } from 'ngx-socket-io';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'froexi_backoffice';
  loggedIn: boolean=false;

  constructor(
    public translate: TranslateService,
    public authService: AuthService,
  ) {
    translate.addLangs(['hu', 'en']);
    translate.setDefaultLang('hu');
    const browserLang = translate.getBrowserLang();

    if (environment.home) {
      translate.use('hu');
      dayjs.locale('hu');
      // this.dateAdapter.setLocale('hu');
    } else {
      translate.use(browserLang.match(/hu|en/) ? browserLang : 'hu');
    }
    // dayjs.locale('hu');
  }

  ngOnInit(): void {
    this.authService.loginComplete.subscribe(result => {
      this.loggedIn = result as boolean
    })
  }

  hasHotels(): boolean {
    return SessionStorage.Hotels ? true : false
  }

  topStyle() {
    let styles = {};

    if (SessionStorage.Hotels) {
      styles["visibility"] = "visible"
    } else {
      styles["visibility"] = "hidden"
    }
    return styles
  }


  showSidebar(): boolean {
    return SessionStorage.IsTokenOk;
  }

  isTokenOk() {
    return SessionStorage.IsTokenOk;
  }
}