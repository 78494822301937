// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest, Subject, takeUntil, map, firstValueFrom } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { InvitationService } from '../../../../../froexishared/src/services/invitation.service';
import { InvitationDto } from '../../../../../froexishared/src/dto/InvitationDto';
import {CurrencySettings} from '../../../../../froexishared/src/dto/CurrencySettingsDto';
import { LanguageService } from '../../../../../froexishared/src/services/language.service';
import SessionStorage from '@app/helpers/SessionStorage';
import AvailableLanguages, { LngCodes } from '../../../../../froexishared/src/AvailableLanguages';
import { LanguageDefDto } from '@app/dto/UserServer';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { SubscriberService } from '@app/services/subscriber.service';
import { SubscriberDto } from '@app/dto/SubsciberDto';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { Subscriber_Parameter } from '../../../../../froexishared/src/dto/Subscriber_Parameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { Subscriber_ParameterService } from '../../../../../froexishared/src/services/subscriber_parameter.service';
import { CanComponentDeactivate } from '@app/interfaces/CanComponentDeactivate';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('#avail') availLanguages: MatSelectionList;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loaded: boolean;
  urlRegex = '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$'  // fragment locator  'i'
  editorForm: FormGroup;
  avaialableLanguages: LngCodes[] = [];

  languages: LngCodes[] = [];
  invitations: InvitationDto[] = [];
  subscriberSettings: SubscriberDto;
  currencySettingParam: SubscriberParameter;
  currencySettings: CurrencySettings = new CurrencySettings()
  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private invitationService: InvitationService,
    private hwAlertService: HwAlertService,
    private subscriberService: SubscriberService,
    private subscriberParameterService: SubscriberParameterService,
    private subscriber_ParameterService: Subscriber_ParameterService,
    private confirmService :HwConfirmationDialogService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder

  ) {

    this.editorForm = fb.group({
      SystemEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      EmailSender: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      AvailLanguages: new FormControl(),
      Body: new FormControl(''),
      EmailSmtpServer: new FormControl('', Validators.required),
      EmailSmtpPort: new FormControl('', Validators.required),
      EmailUserName: new FormControl('', Validators.required,),
      EmailPassword: new FormControl('', Validators.required),
      EmailSocketOpts: new FormControl(''),
      DecimalSeparator : new FormControl(' '),
      NumberOfDecimals : new FormControl(0) ,
      CurrencyCode : new FormControl("HUF",Validators.required)
    })
    this.avaialableLanguages = AvailableLanguages.Languages
    this.avaialableLanguages.forEach(item => {
      this.editorForm.addControl(this.termsCondsUrlName(item.lngCode), new FormControl(''))
      this.editorForm.addControl(this.privacyUrlName(item.lngCode), new FormControl(''))
    })

  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {

    this.topBarService.SetTitleText(this.translate.instant('common.settings'))
    this.topBarService.HotelSelectionChanged.subscribe(event => {
      this.loadAll();
      this.initApp()
    })
    this.loadAll();
    this.initApp();

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  selectionChanged(event) {

   

  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  termsCondsUrlName(inv: string): string {
    return "term_" + inv;
  }

  privacyUrlName(inv: string): string {
    return "priv_" + inv;
  }

  languageName(inv: LngCodes): string {
    return this.avaialableLanguages.find(item => item.lngCode == inv.lngCode).lngName
  }

  languageSelected(inv: LngCodes): boolean {
    const sel = this.editorForm.controls["AvailLanguages"].value as string[];

    const found = sel.includes(inv.lngCode);
    return found ? true : false;
  }

  selectedLanguages(): LngCodes[] {
    let selItems = this.editorForm.controls["AvailLanguages"].value?.toString().split(',');
    let arr: LngCodes[] = [];
    selItems.forEach(item => {
      arr.push(this.avaialableLanguages.find(aval => aval.lngCode == item))
      this.editorForm.controls[this.termsCondsUrlName(item)]?.addValidators( [Validators.pattern(this.urlRegex),Validators.required])
      this.editorForm.controls[this.privacyUrlName(item)]?.addValidators( [Validators.pattern(this.urlRegex),Validators.required])
    })
    return arr;

  }
  loadAll() {
    const subscriber = SessionStorage.SelectedSubscriber;
    const hotelId = SessionStorage.SelectedHotelId;
    const combinedSources = combineLatest({
      invitations: this.invitationService.LoadInvitations(subscriber, hotelId),
      languages: this.languageService.loadAll(subscriber, hotelId),
      subscriberSettings: this.subscriberService.LoadByHotel(subscriber, hotelId)
    }).pipe(takeUntil(this.destroy$)).subscribe(values => {
      this.invitations = values.invitations as InvitationDto[];
      let lng: string[] = values.languages as string[];
      lng.forEach(item => {
        this.languages.push(AvailableLanguages.Languages.find(al => al.lngCode == item))
      })
      let lngArr: string[] = [];
      this.invitations.forEach(item => {
        if (this.languages.find(lngItem => lngItem.lngCode == item.Lang)) {
          lngArr.push(item.Lang)
          this.editorForm.controls["priv_" + item.Lang].setValue(item.PrivacyPolicyUrl)
          this.editorForm.controls["term_" + item.Lang].setValue(item.TermconditionsUrl)
        }
      })
      this.subscriberSettings = values.subscriberSettings as SubscriberDto;
      this.editorForm.controls["AvailLanguages"].setValue(lngArr)
      this.editorForm.controls["EmailSender"].setValue(this.subscriberSettings.EmailSender);
      this.editorForm.controls["EmailSmtpServer"].setValue(this.subscriberSettings.EmailSmtpServer);
      this.editorForm.controls["EmailSmtpPort"].setValue(this.subscriberSettings.EmailSmtpPort);
      this.editorForm.controls["EmailUserName"].setValue(this.subscriberSettings.EmailUserName);
      this.editorForm.controls["EmailPassword"].setValue(this.subscriberSettings.EmailPassword);
      this.editorForm.controls["EmailSocketOpts"].setValue(this.subscriberSettings.EmailSocketOpts);
      this.editorForm.controls["SystemEmail"].setValue(this.subscriberSettings.SystemEmail);
      this.loaded = true;
    })
  }
  submit() {
    const subscriber = SessionStorage.SelectedSubscriber;
    const hotelId = SessionStorage.SelectedHotelId;

    var dtoToSave: InvitationDto[] = [];
    const selectedItems: string[] = this.editorForm.controls["AvailLanguages"].value;
    let itemToUpdate: InvitationDto;
    selectedItems.forEach(lng => {
      const found = this.invitations.find(item => item.Lang == lng)
      if (found) {
        itemToUpdate = {
          Body: found.Body,
          HotelId: found.HotelId,
          Lang: found.Lang,
          Signature: found.Signature,
          Subscriber: found.Subscriber,
          PrivacyPolicyUrl: this.editorForm.controls["priv_" + found.Lang].value.toString(),
          TermconditionsUrl: this.editorForm.controls["term_" + found.Lang].value.toString(),
        }
      } else {
        itemToUpdate = {
          Body: "",
          HotelId: SessionStorage.SelectedHotelId,
          Lang: lng,
          Signature: "",
          Subscriber: SessionStorage.SelectedSubscriber,
          PrivacyPolicyUrl: this.editorForm.controls["priv_" + lng].value.toString(),
          TermconditionsUrl: this.editorForm.controls["term_" + lng].value.toString(),
        }
      }
      dtoToSave.push(itemToUpdate)
    })

    this.subscriberSettings.EmailSender = this.editorForm.controls["EmailSender"].value;
    this.subscriberSettings.EmailSmtpServer = this.editorForm.controls["EmailSmtpServer"].value;
    this.subscriberSettings.EmailSmtpPort = this.editorForm.controls["EmailSmtpPort"].value;
    this.subscriberSettings.EmailUserName = this.editorForm.controls["EmailUserName"].value;
    this.subscriberSettings.EmailPassword = this.editorForm.controls["EmailPassword"].value
    this.subscriberSettings.EmailSocketOpts = this.editorForm.controls["EmailSocketOpts"].value;
    this.subscriberSettings.SystemEmail = this.editorForm.controls["SystemEmail"].value;
    
    this.currencySettings.DecimalSeparator = this.editorForm.controls["DecimalSeparator"].value;
    this.currencySettings.NumberOfDecimals = this.editorForm.controls["NumberOfDecimals"].value;
    this.currencySettings.CurrencyCode = this.editorForm.controls["CurrencyCode"].value;

    this.currencySettingParam.Param_Json_Value = JSON.stringify(this.currencySettings);
    const combinedSources = combineLatest({
      invitationsResult: this.invitationService.Save(subscriber, hotelId, dtoToSave),
      languageResult: this.languageService.saveAll(subscriber, hotelId, this.editorForm.controls["AvailLanguages"].value),
      settingsResult: this.subscriberService.Update(this.subscriberSettings),
      settingsParamResult: this.subscriberParameterService.update(this.currencySettingParam)
    }).pipe(map(response => { }))
      .subscribe(result => {
        this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        this.editorForm.markAsUntouched();
        this.editorForm.markAsPristine();
      })
  }

  async initApp() {
    let sub = await firstValueFrom(this.subscriber_ParameterService.LoadbyTypeCode(SessionStorage.SelectedSubscriber, SessionStorage.SelectedHotelId, 'CURRENCY_SETTINGS'))
    let subs_param = sub as Subscriber_Parameter;
    if (subs_param.Id == 0) {   // param még nem létezik
      // létrehozzuk  
      const newParam: Subscriber_Parameter = {
        Id: -1,
        AssociatedField: "param_json_value",
        SubscriberCode: SessionStorage.SelectedSubscriber,
        HotelId: SessionStorage.SelectedHotelId,
        ParamType: 'Alapvaluta beállításai',
        ParamTypecode: "CURRENCY_SETTINGS"
      }
      const addSubs_ParamResult = await firstValueFrom(this.subscriber_ParameterService.Add(newParam));
      const  defaultValue : CurrencySettings = {
        DecimalSeparator : " ",
        NumberOfDecimals : 0 ,
        CurrencyCode : "HUF"
      } 
      const subsParam: SubscriberParameter = {
        SubscriberCode: SessionStorage.SelectedSubscriber,
        HotelId: SessionStorage.SelectedHotelId,
        Param_Blob_Value: null,
        Id: null,
        Param_Date_Value: null,
        Param_Decimal_Value: null,
        Param_Int_Value: null,
        Param_String_Value: null,
        Param_Typecode: "CURRENCY_SETTINGS",
        Param_Xml_Value: null,
        Param_Json_Value: JSON.stringify(defaultValue)
      }
      try {
        const  addSubsParamResult  = await firstValueFrom(this.subscriberParameterService.add(subsParam));
        const newItem = await firstValueFrom(this.subscriberParameterService.loadByTypeCode(SessionStorage.SelectedSubscriber,SessionStorage.SelectedHotelId,"CURRENCY_SETTINGS"))
        this.loadParamValues()
      } catch (error) {
        alert("Hiba az add_param közben")
      }
    } else {
      this.loadParamValues();
    }
  }

  async loadParamValues() {
    let sub = await firstValueFrom(this.subscriberParameterService.loadByTypeCode(SessionStorage.SelectedSubscriber, SessionStorage.SelectedHotelId, 'CURRENCY_SETTINGS'))
    let subsparam = sub as SubscriberParameter;
    this.currencySettings = JSON.parse(subsparam.Param_Json_Value) as CurrencySettings;
    this.editorForm.controls["DecimalSeparator"].patchValue(this.currencySettings.DecimalSeparator);
    this.editorForm.controls["NumberOfDecimals"].patchValue(this.currencySettings.NumberOfDecimals);
    this.editorForm.controls["CurrencyCode"].patchValue(this.currencySettings.CurrencyCode);
    this.currencySettingParam = subsparam;
  }

  saveButtonColor() {
    return this.editorForm.valid ? "mdc-save" : "mdc-save-disabled"
  }

  isFormDirty()  {
    return this.editorForm.dirty
  }
  //#endregion =================================================================================================================






}
