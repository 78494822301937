
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpEvent, HttpErrorResponse
} from '@angular/common/http';


import { AuthItem } from '../interfaces/auth-item';
import { tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { SessionStorageService } from '@app/services/session-storage.service';

export type InterceptorResponse = Observable<HttpSentEvent
  | HttpHeaderResponse
  | HttpProgressEvent
  | HttpResponse<any>
  | HttpUserEvent<any>>;


@Injectable()
export class HwAuthInterceptorService implements HttpInterceptor {

  constructor(
    protected authService: AuthService,
    protected router: Router,
    private sss: SessionStorageService
    ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): InterceptorResponse {
    if (!this.authService.isAthorizedRequest(req)) {
      return next.handle(req);
    }


    if (!this.authService.isAuthTokenValid()) {
      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.logout();
              }
            }
          }));
    } else {
      const token = this.authService.getAuthToken();
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.access_token}`
        }
      });
      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.logout();
              }
            }
          }));
    }
  }

 

  private logout() {
    this.sss.clear()
    this.router.navigate([`./${'login'}`]);
  }

}
