import { Injectable } from '@angular/core';
import { SelectItem } from '../interfaces/SelectItem';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

constructor() { }
setSelectedUnit(selectedUnit: string) {
  sessionStorage.setItem("selectedUnit", selectedUnit)
}
getSelectedUnit() {
  return sessionStorage.getItem("selectedUnit");
}

setSelectedHotelId(selectedHotelId: string) {
  sessionStorage.setItem("selectedHotelId", selectedHotelId)
}

getSelectedHotelId() : number {
  return parseInt(sessionStorage.getItem("selectedHotelId"));
}

// multi 

setSelectedUnitMulti(hotelId: string) {

  let units: string = "";
  if( hotelId.split('@')[1] == "99") {
    this.getFlattenedHotels().forEach(item => {
      const splitted = item.value.split('@')[0];
      units = units + splitted+","
    });
  } else {
    units = hotelId.split('@')[0] +","; +",";
  }
  units =units.substring(0,units.length-1);
  sessionStorage.setItem('selectedUnitMulti',units)
}
setSelectedHotelIdMulti(hotelId: string) {
  let hotelids: string = "";
  if( hotelId.split('@')[1] == "99") {
    this.getFlattenedHotels().forEach(item => {
      const splitted = item.value.split('@')[1];
      hotelids= hotelId+splitted +","
    });
  } else {
    hotelids = hotelId.split('@')[1] +",";
  }
  hotelids =hotelids.substring(0,hotelids.length-1);
  sessionStorage.setItem('selectedHotelIdMulti',hotelids)

}

getSelectedUnitMulti() : string {
  let units: string = "";
  let flattenedHotels: SelectItem[] = this.getFlattenedHotels()
  if (this.multiHotel) {
    if (!this.IgnoreMultiHotel) {
      if (this.getSelectedHotelId() === 99) { //   99,1,4,10 
        flattenedHotels.forEach(element => {
          units=units + element.value.toString().split("@")[0] + ','
        });
        units = units.substring(0,units.length-1)
      } else { // 99, és a konkrét szálloda
        const sel: string[] = this.getFlattenedHotels()[0].value.toString().split("@")
        units = sel[0] + ',' + this.getSelectedUnit();
       
      }
    }
  } else { // nem multihotel, csak a kiválasztott
    units = this.getSelectedUnit();
   
  }

  return units;
}

getSelectedHotelIdMulti() : string {
 
  let hotels: string = ""
  let flattenedHotels: SelectItem[] = this.getFlattenedHotels()
  if (this.multiHotel) {
    if (!this.IgnoreMultiHotel) {
      if (this.getSelectedHotelId() === 99) { //   99,1,4,10 
        flattenedHotels.forEach(element => {
          hotels=hotels + element.value.toString().split("@")[1] + ','
        });
        hotels = hotels.substring(0,hotels.length-1)
      } else { // 99, és a konkrét szálloda
        const sel: string[] = this.getFlattenedHotels()[0].value.toString().split("@")
        hotels = sel[0] + ',' + this.getSelectedUnit();
        hotels = sel[1] + ',' + this.getSelectedHotelId().toString()
      }
    }
  } else { // nem multihotel, csak a kiválasztott
    hotels = this.getSelectedHotelId().toString();
  }
  return hotels;
}
// setSelectedHotelId(selectedHotelId: number) {
//   sessionStorage.setItem('selectedHotelId',selectedHotelId.toString());
// }
// getSelectedHotelId() {
//   return sessionStorage.getItem("selectedHotelId")
// }
getUserInfo() {
  return JSON.parse(sessionStorage.getItem("userInfo"));
}

getAllHotels() : SelectItem[] {
  let allHotels : SelectItem[] = [];
  allHotels = JSON.parse(sessionStorage.getItem("allHotels")) as SelectItem[];
  return allHotels;
}
setAllHotels(allHotels: SelectItem[] ) {
  sessionStorage.setItem("allHotels",JSON.stringify(allHotels));
}

setFlattenedHotels( flattenedHotels : SelectItem[]) {
  sessionStorage.setItem("flattenedHotels",JSON.stringify(flattenedHotels))
}

getFlattenedHotels() : SelectItem[] {
  let flattenedHotels : SelectItem[] = [];
  flattenedHotels = JSON.parse(sessionStorage.getItem("flattenedHotels")) as SelectItem[];
  return flattenedHotels;
}


getUserName() {
  return JSON.parse(sessionStorage.getItem("userInfo")).UserName;
}
clear() {
   sessionStorage.clear();
}
getUserLanguage() {
  if (sessionStorage.getItem("userInfo")) {
    return JSON.parse(sessionStorage.getItem("userInfo"))["Culture"];
  } else {
    return 'hu';
  }
}

public set IgnoreMultiHotel(value: boolean) {
  sessionStorage.setItem("ignoreMultiHotel",value.toString());
}

public get IgnoreMultiHotel() : boolean {
  return   sessionStorage.getItem("ignoreMultiHotel")== "true" ? true : false  
}


public set multiHotel(isMultiHotel : boolean) {
  sessionStorage.setItem('multiHotel', isMultiHotel.toString())
}
public get multiHotel() : boolean {
  return   sessionStorage.getItem("multiHotel")== "true" ? true : false  }
}
