// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-button-toggle-group {
  flex-wrap: wrap;
}

@media (min-width: 1409px) and (max-width: 3999px) {
  .mat-button-toggle {
    width: 33.3%;
  }
}
.no-licence {
  font-weight: 700;
  font-size: 17px;
  opacity: 1 !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImludml0YXRpb25zLXRpbGUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxlQUFBO0FBQ0o7O0FBRUE7RUFDSTtJQUNJLFlBQUE7RUFDTjtBQUNGO0FBRUE7RUFDSSxnQkFBQTtFQUNBLGVBQUE7RUFDQSxxQkFBQTtBQUFKOztBQUdBO0VBQ0ksb0JBQUE7RUFDQSxZQUFBO0FBQUoiLCJmaWxlIjoiaW52aXRhdGlvbnMtdGlsZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIm1hdC1idXR0b24tdG9nZ2xlLWdyb3VwIHtcbiAgICBmbGV4LXdyYXA6IHdyYXA7XG59XG5cbkBtZWRpYSAobWluLXdpZHRoOiAxNDA5cHgpIGFuZCAgKG1heC13aWR0aDogMzk5OXB4KSB7XG4gICAgLm1hdC1idXR0b24tdG9nZ2xlIHtcbiAgICAgICAgd2lkdGg6IDMzLjMlO1xuICAgIH1cbn1cblxuLm5vLWxpY2VuY2Uge1xuICAgIGZvbnQtd2VpZ2h0OiA3MDA7XG4gICAgZm9udC1zaXplOiAxN3B4O1xuICAgIG9wYWNpdHk6IDEgIWltcG9ydGFudDtcbn1cblxuLmRpc2FibGVkIHtcbiAgICBwb2ludGVyLWV2ZW50czogbm9uZTtcbiAgICBvcGFjaXR5OiAwLjM7XG59Il19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_backoffice/src/app/pages/dashboard/invitations-tile/invitations-tile.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI;IACI,YAAA;EACN;AACF;AAEA;EACI,gBAAA;EACA,eAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,oBAAA;EACA,YAAA;AAAJ;AACA,40BAA40B","sourcesContent":["mat-button-toggle-group {\n    flex-wrap: wrap;\n}\n\n@media (min-width: 1409px) and  (max-width: 3999px) {\n    .mat-button-toggle {\n        width: 33.3%;\n    }\n}\n\n.no-licence {\n    font-weight: 700;\n    font-size: 17px;\n    opacity: 1 !important;\n}\n\n.disabled {\n    pointer-events: none;\n    opacity: 0.3;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
