import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Subscriber_Parameter } from '../dto/Subscriber_Parameter';

@Injectable({
  providedIn: 'root'
})
export class Subscriber_ParameterService {
  private environment

  apiServer: string;
  constructor(private http: HttpClient, @Inject('environment') environment) { 
    this.environment = environment
    this.apiServer = this.environment.apiHost + this.environment.apiPort;
  }

  Add(data : Subscriber_Parameter) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( this.apiServer +  '/api/exi/subscriber_parameter/Add',data,httpOptions)
  }
  Update(data: Subscriber_Parameter) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( this.apiServer +  '/api/exi/subscriber_parameter/Update',data,httpOptions)
  }
  Delete(id: number) {
    return this.http.delete( this.apiServer +  '/api/exi/subscriber_parameter/delete/'+ id)
  }

  LoadAll(subscriber: string, hotelId : number) {
    return this.http.get(this.apiServer  + '/api/exi/subscriber_parameter/LoadAll/' + subscriber + "/" + hotelId)
  }

  LoadbyTypeCode(subscriber:string, hotelId: number, typeCode: string) {
    return this.http.get(this.apiServer  + '/api/exi/subscriber_parameter/LoadByTypeCode/' + subscriber + "/" + hotelId + "/" + typeCode)
  }
}
