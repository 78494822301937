import { Component, ElementRef, Inject, Input, OnInit, ViewChild, inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Socket } from "ngx-socket-io";
import { v4 as uuidv4 } from 'uuid';
import { ChatDialogComponent } from "../../../../froexi_customerfacing/src/app/dialogs/chat-dialog/chat-dialog.component";
import { ChatService } from "./chat.service";
import { YesNoDialogComponent } from "./dialog/yes-no-dialog/yes-no-dialog.component";

@Component({
  selector: "app-chat-app",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {

  @Input('platform') platform = 'FROhok';
  @ViewChild("focusMessageCtrl") focusMessageCtrl!: ElementRef;

  socket = inject(Socket)
  chatService = inject(ChatService)
  dialog = inject(MatDialog)
  messageCtrl: FormControl = new FormControl("");
  keyTranslated: string = ''

  //#region -- fields customer-facing fields --
  reservationInfo = JSON.parse(sessionStorage.getItem("rinf"))
  isChatVisibleForCustomerFacing: boolean = false;
  reOpenChat = sessionStorage.getItem('reOpenChat');
  customerFacingNameCtrl: FormControl = new FormControl('', [Validators.required])
  customerFacingChatData
  customerFacingRoom_uuid: string = ''
  //#endregion

  //#region -- fields backoffice fields --
  userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  backofficeRooms: any[] = [];
  previusRoom = null
  backofficeChatData
  backofficeRoom_uuid: string = ''
  messageCounterArr: IReadedCounter[] = []
  //#endregion

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private dialogRef: MatDialogRef<ChatDialogComponent>,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.keyTranslated = this.translateService.instant('chat.message_placeholder');
    let readCounters = sessionStorage.getItem('readCounters');
    if (readCounters) {
      this.messageCounterArr = JSON.parse(readCounters)
    } else {
      this.messageCounterArr = []
    }

    this.checkCustomerFacing()
    this.checkBackoffice()

    //#region - customer-facing --
    if (this.isChatVisibleForCustomerFacing) {
      this.maximized(sessionStorage.getItem('guestUuid'))
    }
    //#endregion

    //#region -- backoffice --
    this.socket.on('selectedRoomsByHotel', (response) => {
      this.backofficeRooms = response
      console.log('this.backofficeRooms', this.backofficeRooms)
      this.backofficeRooms.forEach(element => {
        let counter = this.messageCounterArr.find(item => item.room_uuid === element.room_uuid)
        if (counter) {
          element.counter = counter.counter;
        }
      })
    })

    this.socket.on('backofficeChatData', result => {
      this.backofficeChatData = result as Array<IChatData>
    })

    this.socket.on('roomDeleted', () => {
      sessionStorage.removeItem('reOpenChat')
    })

    this.socket.on('newMessageCounter', (response) => {
      console.log('newMessageCounter', response)
      this.backofficeRooms.find(item => item.room_uuid === response.room_uuid).message_counter = response.message_counter
    })
    //#endregion

    this.socket.on('chatData', data => {
      this.chatService.sendChatDataToCustomerFacing(data.length)
      this.customerFacingChatData = data as Array<IChatData>
      this.backofficeChatData = data as Array<IChatData>
    })
  }

  //#region -- methods costumer-facing --
  checkCustomerFacing(): void {
    if (this.platform === 'customer-facing') {

      sessionStorage.setItem('reOpenChat', JSON.stringify(this._data.isMinimized));
      if (this._data.isMinimized) {
        this.isChatVisibleForCustomerFacing = true;
        this.customerFacingNameCtrl.setValue(sessionStorage.getItem('customerFacingNameCtrl'))
      }
    }
  }

  minimizeDialog(): void {
    sessionStorage.setItem('reOpenChat', 'true')
    this.dialogRef.close(this.customerFacingChatData?.length)
  }

  createRoom(): void {
    this.isChatVisibleForCustomerFacing = true;

    let guestUuid = uuidv4();
    sessionStorage.setItem('guestUuid', guestUuid)
    sessionStorage.setItem('customerFacingNameCtrl', this.customerFacingNameCtrl.value);

    let request: ICreateRoom = {
      room_uuid: guestUuid,
      room_name: this.normalizedChatName(this.customerFacingNameCtrl),
      room_type: 'FROexi',
      subscriber: this.reservationInfo.Subscriber,
      hotel_id: this.reservationInfo.Hotelid,
      guest_name: this.customerFacingNameCtrl.value
    }
    this.socket.emit('createRoom', request)
  }

  maximized(guestUuid: string): void {
    let request = {
      room_uuid: guestUuid,
      room_name: this.normalizedChatName(this.customerFacingNameCtrl)
    }

    this.socket.emit('maximized', request)
  }

  private normalizedChatName(formControlName: FormControl): string {
    return (
      formControlName.value
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ")
        .join("_") +
      "_szoba"
    );
  }
  //#endregion

  //#region -- methods backoffice --
  checkBackoffice(): void {
    if (this.platform === 'backoffice') {
      let request = {
        name: this.userInfo.Name,
        subscriber: this.userInfo.SubscriberTree.SubscriberCode,
        hotel_id: this.userInfo.SubscriberTree.Htlnum,
      }
      this.socket.emit('receptionist_init', request)
    }
  }

  joinChatRoom(room, index): void {
    room.message_counter = 0;
    sessionStorage.setItem('selectedRoom', JSON.stringify(room));

    if (this.previusRoom === null) {
      let request = {
        room: room,
        previusRoom: ''
      }
      this.socket.emit('joinChatRoom', request)
    } else if (this.previusRoom !== room) {

      // this.previusRoom.counter = this.previusRoom.message_counter || 0;

      // let counter = this.messageCounterArr.find(item => item.room_uuid === this.previusRoom.room_uuid);
      // if (counter) {
      //   counter.counter = this.previusRoom.counter
      // } else {
      //   this.messageCounterArr.push({ room_uuid: this.previusRoom.room_uuid, counter: this.previusRoom.counter })
      // }
      // sessionStorage.setItem('readCounters', JSON.stringify(this.messageCounterArr))

      console.log('this.previusRoom', this.previusRoom)
      let request = {
        room: room,
        previusRoom: this.previusRoom.room_name
      }
      this.socket.emit('joinChatRoom', request);
    }

    this.backofficeRooms.forEach((element, i) => {
      element.hiddenBagde = (i === index);
    });
    this.previusRoom = room;
  }

  deleteRoomById(index: number) {
    this.dialog.open(YesNoDialogComponent,
      { data: { messageText: '', messageType: 'INFORMATION' } }).afterClosed().subscribe(data => {
        if (data) {
          this.socket.emit('deleteChatRoom', this.backofficeRooms[index])
          this.backofficeChatData = null;
        }
      })
  }
  //#endregion

  //#region -- methods message --
  sendMessage() {
    let sendMessage = {}

    // Customerfacing
    if (this.platform === 'customer-facing') {
      sendMessage = {
        room_uuid: sessionStorage.getItem('guestUuid'),
        chat: this.messageCtrl.value,
        chat_timestamp: new Date(),
        sender: this.customerFacingNameCtrl.value,
        platform: 'customer-facing'
      };
      //console.log('customer-facing message is: ', sendMessage)
    }

    // Bakcoffice
    if (this.platform === 'backoffice') {
      sendMessage = {
        room_uuid: JSON.parse(sessionStorage.getItem('selectedRoom')).room_uuid,
        chat: this.messageCtrl.value,
        chat_timestamp: new Date(),
        sender: this.userInfo.Name,
        platform: 'backoffice'
      };
      //console.log('bakcoffice message is: ', sendMessage)
    }

    this.socket.emit('message', sendMessage)
    this.messageCtrl.setValue("");
    this.focusMessageCtrl.nativeElement.focus();
  }

  sendMessageOnEnter(event: KeyboardEvent) {
    if (event!.key === "Enter") this.sendMessage();
  }

  sendMessageOnClick() {
    this.sendMessage();
  }
  //#endregion


  getBagdeNumber(room): number {
    return (room.message_counter || 0) - (room.counter || 0);
  }
}

//#region -- interfaces --
interface ICreateRoom {
  room_uuid: string,
  room_name: string,
  room_type: string,
  subscriber: string,
  hotel_id: number,
  guest_name: string,
}

interface IChatData {
  sender: string,
  text: string,
  chat: string,
  chat_timestamp: Date
}
interface IReadedCounter {
  room_uuid: string,
  counter: string,
}

//#endregion