import { Inject,Injectable } from '@angular/core';
import { Language } from '../dto/Language';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

@Injectable()
export class LanguageService {

  private environment
constructor(private http: HttpClient,@Inject('environment') environment) {
  this.environment = environment
 }

  loadAll(Subscriber : string, hotelId : number)  {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get( apiServer +  '/api/exi/subscribers/LoadSubscriberLanguages/'+ Subscriber + "/" + hotelId)
  }

  saveAll(Subscriber : string, hotelId : number, data : string[]) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post( apiServer +  '/api/exi/subscribers/SaveSubscriberLanguages/'+ Subscriber + "/" + hotelId, data,httpOptions)
  }
}
