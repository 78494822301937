import { Injectable, inject } from '@angular/core';
import { CanDeactivateFn, Router, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';



export const CanDeactivateGuard : CanDeactivateFn<unknown> = (component : any, currentRoute, currentState,nextState) : boolean => {
 
    const router = inject(Router);
    const translate = inject(TranslateService);
    if(component && component.isFormDirty()) {
      const confirmation = confirm( translate.instant("common.save_warning"))
      if( confirmation) {
        router.navigate['dashboard']
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

// common.save_warning