// Angular
import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
// Hostware
import { SelectItem } from '@app/interfaces/SelectItem';
import { BrandingService } from '../../../../../froexishared/src/services/branding.service';
import SessionStorage from '@app/helpers/SessionStorage';
import { TopbarService } from '@app/services/topbar.service';
import { TranslateService } from '@ngx-translate/core';
import { BrandingDto } from '../../../../../froexishared/src/dto/BrandingDto'
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { ImagePreviewService } from '@app/services/image-preview.service';
import { CanComponentDeactivate } from '@app/interfaces/CanComponentDeactivate';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';

@AutoUnsubscribe()
@Component({
  selector: 'design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('primaryColor') primaryColor: HTMLInputElement
  @ViewChild('secondaryColor') secondaryColor: HTMLInputElement
  @ViewChild('previewLogoCanvas', { static: true }) previewLogoCanvas: ElementRef;
  @ViewChild('previewCoverImageCanvas', { static: true }) previewCoverImageCanvas: ElementRef;


  previewLogoContext: CanvasRenderingContext2D;
  previewCoverImageContext: CanvasRenderingContext2D;
  fonts: SelectItem[] = [{
    value: "RO",
    label: "Roboto"
  },
  {
    value: "OS",
    label: "Open Sans"
  },
  {
    value: "LA",
    label: "Lato"
  },
  {
    value: "OS",
    label: "Oswald"
  },
  {
    value: "R",
    label: "Slabo 27px"
  },
  {
    value: "RC",
    label: "Roboto Condensed"
  },
  {
    value: "MO",
    label: "Montserrat"
  },
  {
    value: "SS",
    label: "Source Sans Pro"
  },

  {
    value: "RA",
    label: "Raleway"
  },
  {
    value: "PT",
    label: "PS Sans"
  },


  ]
  editorForm: FormGroup;
  editedItem: BrandingDto;
  newItem: boolean;
  capturedLogoImage = new Image();
  capturedCoverImage = new Image()

  loadBrandingData$: Subscription;
  addBrandingData$: Subscription;
  updateBrandingData$: Subscription;
  constructor(
    private topBarService: TopbarService,
    private brandingService: BrandingService,
    private translate: TranslateService,
    public hwAlertService: HwAlertService,
    private imagePreviewService: ImagePreviewService,
    private confirmService : HwConfirmationDialogService,
    private fb: FormBuilder
  ) {

    this.editorForm = fb.group({
      Logo: new FormControl(''),
      PrimaryColor: new FormControl(''),
      SecondaryColor: new FormControl(''),
      FontName: new FormControl(''),
    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {

    this.loadAll();
    this.topBarService.SetTitleText(this.translate.instant('style.style'))
    this.topBarService.HotelSelectionChanged.subscribe(evt => {
      this.loadAll();
    })
  }
  ngAfterViewInit(): void {
    this.previewLogoContext = this.previewLogoCanvas.nativeElement.getContext("2d");
    this.previewCoverImageContext = this.previewCoverImageCanvas.nativeElement.getContext("2d")
  }
  ngOnDestroy(): void {
    this.addBrandingData$?.unsubscribe();
    this.loadBrandingData$?.unsubscribe();
    this.updateBrandingData$?.unsubscribe();
    this.editorForm.reset();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  

  onLogoFileSelected(event) {
    const file: File = event.target.files[0];
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.hwAlertService.error(this.translate.instant("style.file_type_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      return;
    }

    if( file.size > 100000) {
      this.hwAlertService.error(this.translate.instant("style.file_size_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      return
    }
    this.capturedLogoImage = new Image()
    this.capturedLogoImage.onload = () => this.drawLogoImageScaled(this.capturedLogoImage)

    if (file) {
      this.capturedLogoImage.src = URL.createObjectURL(file)
    }
  }
  onCoverImageFileSelected(event) {
    const file: File = event.target.files[0];
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.hwAlertService.error(this.translate.instant("style.file_type_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      return;
    }

    if( file.size > 100000) {
      this.hwAlertService.error(this.translate.instant("style.file_size_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      return
    }
    this.capturedCoverImage = new Image()
    this.capturedCoverImage.onload = () => this.drawCoverImageScaled(this.capturedCoverImage)

    if (file) {
      this.capturedCoverImage.src = URL.createObjectURL(file)
    }
  }


  drawLogoImageScaled(img: any) {
    let imageHeight: number;
    let imageWidth: number;

    //const imageRatio =img.height/img.width;
    const ratio = img.width / img.height;

    if (img.height <= 80 || img.width <= 300) {
      this.previewLogoContext.clearRect(0, 0, 300, 80)
      this.previewLogoCanvas.nativeElement.width = img.width;
      this.previewLogoCanvas.nativeElement.height = img.height;
      this.previewLogoContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height)
    } else {
      imageHeight = 80;
      imageWidth = 80 * ratio;

      this.previewLogoCanvas.nativeElement.width = imageWidth;
      this.previewLogoCanvas.nativeElement.height = imageHeight;
      this.previewLogoContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, imageWidth, imageHeight)
    }

    const dataUrl = this.previewLogoCanvas.nativeElement.toDataURL();
    this.editedItem.Logo = dataUrl;
    this.imagePreviewService.changeDesign(this.buildDto());
  }

  drawCoverImageScaled(img: any) {
    let imageHeight: number;
    let imageWidth: number;

    //const imageRatio =img.height/img.width;
    const ratio = img.width / img.height;

    if (img.width  < 800) {
      this.previewLogoContext.clearRect(0, 0, 800, 800/ratio)
      this.previewCoverImageCanvas.nativeElement.width = img.width;
      this.previewCoverImageCanvas.nativeElement.height = img.height;
      this.previewCoverImageContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height)
    } else {
      imageHeight = 800/ratio;
      imageWidth = 800;

      this.previewCoverImageCanvas.nativeElement.width = imageWidth;
      this.previewCoverImageCanvas.nativeElement.height = imageHeight;
      this.previewCoverImageContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, imageWidth, imageHeight)
    }

    const dataUrl = this.previewCoverImageCanvas.nativeElement.toDataURL();
    this.editedItem.CoverImage = dataUrl;
    this.imagePreviewService.changeDesign(this.buildDto());

  }
  primaryColorChanged() {
    this.imagePreviewService.changeDesign(this.buildDto());
  }

  secondaryColorChanged() {
    this.imagePreviewService.changeDesign(this.buildDto());
  }

  fontChanged() {
    this.imagePreviewService.changeDesign(this.buildDto());
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAll() {
    const subscriber = SessionStorage.SelectedSubscriber;
    const hotelId = SessionStorage.SelectedHotelId;


    this.loadBrandingData$ = this.brandingService.LoadOnebySubscriber(subscriber, hotelId).subscribe(
      {
        next: (result) => {
          this.newItem = false;
          this.editedItem = result as BrandingDto;
          // set defaults 
          this.editorForm.controls["PrimaryColor"].setValue(this.editedItem.PrimaryColor);
          this.editorForm.controls["SecondaryColor"].setValue(this.editedItem.SecondaryColor);
          this.editorForm.controls["FontName"].setValue(this.editedItem.FontName);
          this.imagePreviewService.changeDesign(this.buildDto());

        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 400) {
            this.newItem = true;
            this.editedItem = new BrandingDto();
            this.editedItem.Logo = "";
            this.editedItem.PrimaryColor = "#000000";
            this.editedItem.SecondaryColor = "#FFFFFF";
            this.editorForm.controls["PrimaryColor"].setValue("#000000");
            this.editorForm.controls["SecondaryColor"].setValue("#FFFFFF");
            this.editorForm.controls["FontName"].setValue(this.fonts[0].label);
            this.imagePreviewService.changeDesign(this.buildDto());

          }
        }
      }
    )
  }


  submit() {
    this.editedItem.Subscriber = SessionStorage.SelectedSubscriber;
    this.editedItem.HotelId = SessionStorage.SelectedHotelId;
    this.editedItem.PrimaryColor = this.editorForm.controls["PrimaryColor"].value;
    this.editedItem.SecondaryColor = this.editorForm.controls["SecondaryColor"].value;
    this.editedItem.FontName = this.editorForm.controls["FontName"].value;

    if (this.newItem) {
      this.addBrandingData$ = this.brandingService.Add(this.editedItem).subscribe(
        {
          next: (result) => {
            this.showOkMessage();
          },
          error: (error) => {
            this.showErrorMessage()
          }
        })
    } else {
      this.addBrandingData$ = this.brandingService.Update(this.editedItem).subscribe(
        {
          next: (result) => {
            this.showOkMessage();
          },
          error: (error) => {
            this.showErrorMessage()
          } 
        })
    }
  }

  showErrorMessage() {
    this.hwAlertService.error(this.translate.instant("common.save_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
  }

  showOkMessage() {
    this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    this.editorForm.markAsUntouched();
    this.editorForm.markAsPristine();

  }

  buildDto(): BrandingDto {
    let br = new BrandingDto();
    br.FontName = this.editorForm.controls["FontName"].value;
    br.PrimaryColor = this.editorForm.controls["PrimaryColor"].value;
    br.SecondaryColor = this.editorForm.controls["SecondaryColor"].value;
    br.Logo = this.editedItem.Logo;
    return br;
  }
  fontStyle(item: SelectItem) {
    let styles = {};
    styles["font-family"] = item.label;
    return styles;

  }

  primaryColorButtonStyle() {
    let styles = {};
    return styles;
  }

  secondaryColorButtonStyle() {
    let styles = {};
    return styles;
  }

  getBase64StringFromDataURL(dataURL: string): string {
    return dataURL.replace('data:', '').replace(/^.+,/, '')
  }

  isFormDirty()  {
    return this.editorForm.dirty
  }
  //#endregion =================================================================================================================




}
