
// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

// Hostware
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginDto } from '@app/dto/LoginDto';
import { AuthItem } from '@app/interfaces/auth-item';
import { Router } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { SelectItem } from '@app/interfaces/SelectItem';
import { RequestResult } from '@app/dto/RequestResult'
import SessionStorage from '@app/helpers/SessionStorage';
import { SubscriberTreeDto, UserServerDto } from '@app/dto/UserServer';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { Subscriber_ParameterService } from '../../../../../froexishared/src/services/subscriber_parameter.service';
import { TranslateService } from '@ngx-translate/core';
import { TopbarService } from '@app/services/topbar.service';
@AutoUnsubscribe()
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  login$: Subscription;
  getRawUserData$: Subscription;
  loadSubscriberParameters$ : Subscription;
  addSubscriberParameters$ : Subscription;
  loadSubscriber_Parameters$ : Subscription;
  addSubscriber_Parameter$ : Subscription;

  flattenedHotels: SelectItem[] = [];
  allHotels: SelectItem[] = [];

  loading: boolean;
  loginForm: FormGroup;
  constructor(
    // private spinnerService: SpinnerService,
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private topBarService: TopbarService,
    private subscriberParameterService : SubscriberParameterService,
    private subscriber_ParameterService : Subscriber_ParameterService,


  ) {
    this.loginForm = this.fb.group({
      'userName': new FormControl(''),
      'password': new FormControl(''),

    })

  }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.login$?.unsubscribe();
    this.getRawUserData$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  login() {
    this.login$ = this.authService.login(this.loginForm.controls["userName"].value, this.loginForm.controls["password"].value).subscribe( result=> {
      this.getRawUserData$ = this.userService.getUserInfo(this.loginForm.controls["userName"].value).subscribe(
        {
          next: (userServer) => {
            const userInf = userServer as UserServerDto;
            sessionStorage.setItem('userInfo', JSON.stringify(userInf));
            const hello = SessionStorage.HasExiLicence
            const bello = SessionStorage.HasExiLicence
            this.flattenedHotels = [];
            const oneSub: SubscriberTreeDto = userInf.SubscriberTree as SubscriberTreeDto
            const subs: SubscriberTreeDto[] = [];

            // fill allHotels
            subs.push(oneSub);  
            this.loopData(oneSub, '');
            SessionStorage.Email = this.loginForm.controls["userName"].value;
            // fill flattenedHotel
            if (oneSub.Subscribers?.length > 0) {
              this.flattenedHotels.push({ label: oneSub.SubscriberCode + "- " + oneSub.ShortName, value: oneSub.SubscriberCode + "@" + oneSub.Htlnum } )
              this.loopSubscriberData(userInf.SubscriberTree);
            } else {
              this.flattenedHotels.push({ label: oneSub.SubscriberCode + " - " + oneSub.ShortName, value: oneSub.SubscriberCode + "@" + oneSub.Htlnum  })
            }
            SessionStorage.Hotels = this.flattenedHotels;

            SessionStorage.SelectedHotel = this.flattenedHotels[0].value.toString().includes('@99') ? this.flattenedHotels[1].value : this.flattenedHotels[0].value;
           
            // this.flattenedHotels.forEach( hotel=> {
            //   const subscriber : string   = hotel.value.split('@')[0];
            //   const hotelId : number      = parseInt(hotel.value.split("@")[1])
            //   // itt majd az első hotelt loadoljuk be, a többitz majd hotelváltáskor töltjük

            //   // this.loadSubscriber_Parameters$ = this.subscriber_ParameterService.LoadbyTypeCode(subscriber,hotelId,'GUEST_DATA_FIELDS').subscribe(subs_param=>{
            //   //     console.log(subs_param);
            //   // })
            // })
            this.authService.setLoginComplete(true);
           //this.topBarService.SetTitleText(this.translate.instant('common.dashboard'))
            //  check params
            
            this.router.navigateByUrl("/dashboard")
          },
          error: (err: HttpErrorResponse) => {

          }
        })
    })
    
 
 
      // complete: () =>  this.router.navigate("dashboard") 

   }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loopData(obj, stack) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == "object") {
          if (!this.allHotels.find(item => item.value == obj["SubscriberCode"] + "@" + obj["Htlnum"])) {
            if (obj.RgtIdx) {
              if (parseInt(obj.RgtIdx) - parseInt(obj.LftIdx) === 1) {
                this.allHotels.push({ value: obj.SubscriberCode + "@" + obj.Htlnum, label: obj.SubscriberCode + " / (" + obj.Htlnum + ")" })
              }
            }

          }
          this.loopData(obj[property], stack + '.' + property);
        }
      }
    }
  }


  loopSubscriberData(data: SubscriberTreeDto) {

    data?.Subscribers.forEach(item => {
      this.flattenedHotels.push({ label: item["SubscriberCode"] + " - " + item.ShortName, value: item.SubscriberCode + "@" + item.Htlnum , title: item.SubscriberCode})
      this.loopSubscriberData(item);
    });
  }
  //#endregion =================================================================================================================


}
