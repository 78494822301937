import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../../../../froexishared/src/services/language.service';
import SessionStorage from '@app/helpers/SessionStorage';
import { LngCodes } from       '../../../../../../froexishared/src/AvailableLanguages';
import AvailableLanguages from '../../../../../../froexishared/src/AvailableLanguages';
import { TopbarService } from '@app/services/topbar.service';

@Component({
  selector: 'used-languages-tile',
  templateUrl: './used-languages-tile.component.html',
  styleUrls: ['./used-languages-tile.component.scss']
})
export class UsedLanguagesTileComponent implements OnInit {

  constructor( private languageService : LanguageService,
    
      private topbarService: TopbarService  ) { }
  availableLanguages : LngCodes[] = AvailableLanguages.Languages
  selectedLanguages: string[]=[];
  ngOnInit() {
    this.loadAll();
    this.topbarService.HotelSelectionChanged.subscribe(result=> {
      console.log(result)
      this.loadAll();
    })
  }

  languageSelected ( lngCode: string) : boolean {
    return this.selectedLanguages.find( item=>item == lngCode) ? true : false
  }

  lngName ( lngCode : string) : string {
    return this.availableLanguages.find( item=>item.lngCode == lngCode).lngName;
  }

  loadAll() {
    this.languageService.loadAll(SessionStorage.SelectedSubscriber,SessionStorage.SelectedHotelId).subscribe( result=> {
      this.selectedLanguages = result as string[];
   })
  }
}
